import { createAction } from "@reduxjs/toolkit";

import {
  requestMilestoneCreate as requestMilestoneCreateSlice,
  successMilestoneCreate as successMilestoneCreateSlice,
  errorMilestoneCreate as errorMilestoneCreateSlice,
} from "../slices/milestonecreate";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";

export const requestMilestoneCreate = createAction("REQUEST_MILESTONE_CREATE");
export const successMilestoneCreate = createAction("SUCCESS_MILESTONE_CREATE");
export const errorMilestoneCreate = createAction("ERROR_MILESTONE_CREATE");

export const milestoneCreate = (token, project_id,milestoneData) => async (dispatch) =>{
    const requestOptions = {
        method: "POST",
        headers: {"Authorization": token,"Content-Type":"application/json"},
        body:JSON.stringify({
            project_id:project_id,
            billNo:milestoneData.billNo,
            dateIssued:milestoneData.dateIssued,
            amount:milestoneData.amount,
            milestone_status_id:milestoneData.milestone_status_id,
            details:milestoneData.details,
            milestone_types_id:milestoneData.milestone_types_id,
            })
    }
    try {
        dispatch(requestMilestoneCreateSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects/"+project_id+"/milestones/",requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects/"+project_id+"/milestones/",requestOptions);
        }
        const data = await response.json();
        if (data.status === "SUCCESS") {
            dispatch (successMilestoneCreateSlice({data}));
        }
        else{
            dispatch(errorMilestoneCreateSlice({data}));
        }
    } catch (error) {
        dispatch(errorMilestoneCreateSlice({error}));
    }
};