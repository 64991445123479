import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import background from "../../assets/LoginPageBackground.jpg";
import Footer from "../../components/Footer";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { register } from "../../redux/actions/register";
import { registerReset } from "../../redux/slices/register";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loader from "../../components/Loader/index"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function RegisterPage() {
  /**------------------------------------------------------------------------
     * CONSTS HERE
     -------------------------------------------------------------------------*/
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  //STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const [isFirstNameEmpty, setIsFirstNameEmpty] = useState();
  const [isLastNameEmpty, setIsLastNameEmpty] = useState();
  const [isEmailEmpty, setIsEmailEmpty] = useState();
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState();

  const [isErrorRegisteringIn,setIsErrorRegisteringIn] = useState(false);
  const [isSuccessRegisteringIn, setIsSuccessRegisteringIn] = useState(false)

  const {
    isRegisteringIn,
    successRegisteringIn,
    errorRegisteringIn,
    registerData,
    errorRegisteringData,
  } = useSelector((state) => state.registerReducer,shallowEqual);
  const dispatch = useDispatch();


  /**-----------------------------------------------------
   * UseEffects here
   * -----------------------------------------------------
   */

  useEffect(()=>{
      setIsErrorRegisteringIn(false)
  },[])

  useEffect(()=>{
    setIsErrorRegisteringIn(errorRegisteringIn)
  },[errorRegisteringIn])

  useEffect(()=>{
    setIsSuccessRegisteringIn(successRegisteringIn)
  },[successRegisteringIn])


  /**-----------------------------------------------------
   * Handlers here
   * -----------------------------------------------------
   */

   const handleErrorRegisteringIn = ()=>{
    setIsErrorRegisteringIn(false);
    toast.error(`User not registered. ${errorRegisteringData.data[0]}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    toast.clearWaitingQueue();
    dispatch(registerReset())
   }

   const handleSuccessRegisteringIn = ()=>{
    setIsSuccessRegisteringIn(false);
    toast.success(`User registered correctly. Please login`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    toast.clearWaitingQueue();
    dispatch(registerReset())
    navigate("/");
   }

  const handleBackToLoginClick = () => {
    navigate("/");
  };

  //Log In Handler
  const handleRegisterClick = () => {
    let errorCount = 0;
    let errorList = [];
    if (firstName.length == 0) {
      errorList.push("First Name can´t be blank\n");
      setIsFirstNameEmpty(true);
      errorCount++;
    } else {
      setIsFirstNameEmpty(false);
    }
    if (lastName.length == 0) {
      errorList.push("Last Name can´t be blank\n");
      setIsLastNameEmpty(true);
      errorCount++;
    } else {
      setIsLastNameEmpty(false);
    }
    if (email.length == 0) {
      errorList.push("Email can´t be blank\n");
      setIsEmailEmpty(true);
      errorCount++;
    } else {
      setIsEmailEmpty(false);
    }

    if (!isEmailError) {
      errorList.push("Email not valid\n");
      errorCount++;
    }

    if (phoneNumber.length != 0) {
      if (!isValidPhoneNumber(phoneNumber)) {
        errorList.push("Invalid phone number. If not sure leave it blank\n");
        errorCount++;
      }
    }
    if (password.length == 0) {
      errorList.push("Password can´t be blank\n");
      setIsPasswordEmpty(true);
      errorCount++;
    } else {
      setIsPasswordEmpty(false);
    }

    if (errorCount > 0) {
      alert(`Some errors occured please check:\n ${errorList.join("")}`); //el join lo estas usando para quitar las comas a la hora de mostrar el arreglo de strings
    } else {
      const registerData = {
        firstName: firstName,
        lastName: lastName,
        userName: email,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
      };
      dispatch(register({ registerData }));
    }
  };

  //First Name Change Handler
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setIsFirstNameEmpty(event.target.value.emp);
  };

  //Last Name Change handler
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setIsLastNameEmpty(false);
  };

  //Email Change handler
  const handleEmailChange = (event) => {
    let email = event.target.value;
    setEmail(email);
    setIsEmailError(/\S+@\S+\.\S+/.test(email));
  };

  //Password change hander
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsPasswordEmpty(false);
  };

  //Phone Change handler
  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  //Phone Key Press Handler
  const handlePhoneKeyDown = (event) => {
    let keyCode = parseInt(event.keyCode);

    //Numeric Keypad
    if (
      (keyCode < 95 || keyCode > 105) &&
      keyCode !== 8 &&
      keyCode !== 17 &&
      keyCode !== 107 &&
      (keyCode < 47 || keyCode > 58)
    ) {
      event.preventDefault();
    }
  };
  return (
    //DIV CONTAINER FOR THE WHOLE PAGE. I AM ADDING THE BACKGROUND IMAGE HERE
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        opacity: "1",
      }}
      className="w-screen h-screen flex flex-col justify-center"
    >
      {isErrorRegisteringIn?handleErrorRegisteringIn():undefined}
      {isSuccessRegisteringIn?handleSuccessRegisteringIn():undefined}
      <div
        className="bg-white flex-col m-20 drop-shadow-2xl self-center"
        style={{ width: "668px", height: "750px" }}
      >
        {isRegisteringIn ? (
          <Loader title={"We are creating your user"} body={"Please wait a few seconds while we try to create your user"}/>
        ) : (
          <div>
            <div>
              <h1 className="capitalize text-heading-blue font-bold m-16 text-3xl">
                {t("registerWindowTitle")}
              </h1>
            </div>
            <div className="flex flex-col m-8 pr-20">
              <div className="m-2 self-end">
                <label className="uppercase font-bold text-field-blue text-xl text-right">
                  {t("firstName")}(*)
                  <input
                    type="text"
                    className={`border-2 ${
                      isFirstNameEmpty ? "border-red-500" : "border-field-blue"
                    } p-1 ml-2`}
                    placeholder={t("typeFirstName")}
                    onChange={handleFirstNameChange}
                  />
                </label>
              </div>

              <div className="m-2 self-end">
                <label className="uppercase font-bold text-field-blue text-xl text-right">
                  {t("lastName")}(*)
                  <input
                    type="text"
                    className={`border-2 ${
                      isLastNameEmpty ? "border-red-500" : "border-field-blue"
                    } p-1 active:border-field-blue ml-2`}
                    placeholder={t("typeLastName")}
                    onChange={handleLastNameChange}
                  />
                </label>
              </div>

              <div className="m-2 self-end">
                <label className="uppercase font-bold text-field-blue text-xl text-right">
                  {t("email")}(*)
                  <input
                    type="email"
                    className={`border-2 ${
                      isEmailEmpty || !isEmailError
                        ? "border-red-500"
                        : "border-field-blue"
                    } p-1 active:border-field-blue ml-2`}
                    placeholder={t("typeEmail")}
                    onChange={handleEmailChange}
                  />
                </label>
              </div>

              <div className="m-2 self-end">
                <label className="uppercase font-bold text-field-blue text-xl text-right">
                  {t("password")}(*)
                  <input
                    type="password"
                    className={`border-2 ${
                      isPasswordEmpty ? "border-red-500" : "border-field-blue"
                    } p-1 active:border-field-blue ml-2`}
                    placeholder={t("typePassword")}
                    onChange={handlePasswordChange}
                  />
                </label>
              </div>

              <div className="m-2 self-end">
                <label className="uppercase font-bold text-field-blue text-xl text-right">
                  {t("phone")}
                  <input
                    type="tel"
                    className="border-2 border-field-blue p-1 active:border-field-blue ml-2"
                    placeholder={t("typePhone")}
                    onChange={handlePhoneChange}
                    onKeyDown={handlePhoneKeyDown}
                  />
                </label>
              </div>
            </div>
            <div>
              <button
                onClick={handleRegisterClick}
                className="capitalize bg-button-blue rounded-full text-white font-bold w-48 m-5 hover:bg-button-over-blue"
              >
                {t("register")}
              </button>
            </div>
            <div>
              <hr className="m-8 border-1 border-black" />
            </div>
            <div>
              <p>{"Back to LogIn page"}</p>
              <p
                className="text-button-blue cursor-pointer"
                onClick={handleBackToLoginClick}
              >
                {"LogIn"}
              </p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
