import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  navBarItemTextStyle,
  navBarItemActiveStyle,
  navBarItemUnActiveStyle,
} from "../../assets/styles";

export default function NavBarItem({ active, title, url, visible }) {
  const navigate = useNavigate();
  /**----------------------------------------------------------------------------------------
     * States Here
     -----------------------------------------------------------------------------------------*/
  const [itemActive, setItemActive] = useState(false);

  /**----------------------------------------------------------------------------------------
     * Handlers HERE
     -----------------------------------------------------------------------------------------*/
  const handleNavBarItemClick = () => {
    navigate(`${url}`);
  };

  /**----------------------------------------------------------------------------------------
     * VIEW RENDERS HERE
     -----------------------------------------------------------------------------------------*/
  const renderNavBarItem = () => (
    <div className="flex flex-col" onClick={handleNavBarItemClick}>
      <p className={navBarItemTextStyle}>{title}</p>
      <hr
        className={`${
          itemActive ? `${navBarItemActiveStyle}` : `${navBarItemUnActiveStyle}`
        }`}
      />
    </div>
  );

  useEffect(() => {
    if (title == active) {
      setItemActive(true);
    } else {
      setItemActive(false);
    }
  }, []);
  return <div>{visible === true ? renderNavBarItem() : undefined}</div>;
}
