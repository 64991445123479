import { useTranslation } from 'react-i18next';

export default function ProjectGeneralData({
  projectName,
  projectOwner,
  startDate,
  dueDate,
}) {

    const datesDiffInMilliSecs = (new Date(`${dueDate}`)) - (new Date(`${startDate}`));
        const days = datesDiffInMilliSecs/(1000*60*60*24)
        //setDaysLeft(days.toFixed());

        const daysLeft = days.toFixed()
        const { t, i18n } = useTranslation();


    return (
        <div className="flex flex-col items-start h-full justify-between">
            <p className="text-field-blue font-bold text-2xl">{projectName}</p>
            <p className="lowercase text-field-blue font-bold text-xl">{t("for")}: {projectOwner}</p>
            <br/>
            <p className="text-field-blue font-bold text-l">{t("startDate")}: {startDate}</p>
            <p className="text-field-blue font-bold text-l">{t("dueDate")}: {dueDate}</p>
            <p className="text-field-blue font-bold text-l">{t("daysLeft")}: {daysLeft}</p>
        </div>
    );
}
