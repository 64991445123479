import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";
import { createAction } from "@reduxjs/toolkit";

import {
  requestProjectDeletion as requestProjectDeletionSlice,
  succesProjectDeletion as succesProjectDeletionSlice,
  errorProjectDeletion as errorProjectDeletionSlice,
} from "../slices/deleteproject";

export const requestProjectDeletion = createAction("REQUEST_PROJECT_DELETION");
export const succesProjectDeletion = createAction("SUCCESS_PROJECT_DELETION");
export const errorProjectDeletion = createAction("ERROR_PROJECT_DELETION");

export const deleteProject = (token,project_id) => async (dispatch)=>{
    const requestOptions = {
        method: "DELETE",
        headers: {"Authorization": token,"Content-Type":"application/json"},
    }

    try {
        dispatch(requestProjectDeletionSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects/"+project_id,requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects/"+project_id,requestOptions);
        }
        
        
        
        const data = await response.json();
        dispatch(succesProjectDeletionSlice({data}));
    } catch (error) {
        //Lo que se hace cuando hay error
        dispatch(errorProjectDeletionSlice({error}));
    }
};
