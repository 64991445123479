import { useEffect, useState } from "react";
import {
  projectCardActiveStyle,
  projectCardCompletedStyle,
  projectCardOpportunityStyle,
  projectCardPendingStyle,
} from "../../assets/styles";
import AdditionalBudgetSummary from "./AdditionalBudgetSummary";
import BudgetSummary from "./BudgetSummary";
import ProjectGeneralData from "./ProjectGeneralData";
import ProjectGeneralStats from "./ProjectGeneralStats";
import ProjectOperations from "./ProjectOperations";

export default function ProjectSummaryCard({
  projectName,
  projectOwner,
  startDate,
  dueDate,
  budgetTotal,
  budgetReceived,
  budgetPending,
  additionalBudgetReceived,
  additionalBudgetPending,
  projectState,
  milestones,
  dueMilestones,
  project_id,
  hideControls
}) {
  /**------------------------------------------------------------------------
   * States Here
   *------------------------------------------------------------------------*/
  const [cardStyle, setCardStyle] = useState();


  useEffect(() => {
    switch (projectState) {
      case "completed":
        setCardStyle(projectCardCompletedStyle);
        break;
      case "pending":
        setCardStyle(projectCardPendingStyle);
        break;
      case "active":
        setCardStyle(projectCardActiveStyle);
        break;
      case "opportunity":
        setCardStyle(projectCardOpportunityStyle);
        break;

      default:
        break;
    }
  }, []);
  return (
    <div className="flex h-48 items-center border-2 m-2 py-28 rounded-xl">
      <div>
        <p className={cardStyle} style={{writingMode:"vertical-lr",textOrientation:"upright"}}>{projectState}</p>
      </div>
      <div className="h-48 w-72">
        <ProjectGeneralData
          projectName={projectName}
          projectOwner={projectOwner}
          startDate={startDate}
          dueDate={dueDate}
        />
      </div>
      <div className="h-48 mx-3">
        <BudgetSummary
          budgetTotal={budgetTotal}
          budgetReceived={budgetReceived}
          budgetPending={budgetPending}
          key={project_id}
        />
      </div>
      <div className="h-48 mx-3">
        <AdditionalBudgetSummary
          additionalBudgetPending={additionalBudgetPending}
          additionalBudgetReceived={additionalBudgetReceived}
        />
      </div>
      <div className="h-48 mx-3">
        <ProjectGeneralStats
          additionalBudgetReceived={additionalBudgetReceived}
          budgetReceived={budgetReceived}
          dueMielstones={dueMilestones}
          milestones={milestones}
        />
      </div>
      <div className="h-48 mx-3 w-max">
        <ProjectOperations projectName={projectName} project_id={project_id} hideControls={hideControls}/>
      </div>
    </div>
  );
}
