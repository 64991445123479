import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function ProjectGeneralStats({
  budgetReceived,
  additionalBudgetReceived,
  milestones,
  dueMielstones,
}) {
  const [budgetReceivedState, setBudgetReceivedState] = useState(0);
  const [additionalBudgetReceivedState, setAdditionalBudgetReceivedState] =
    useState(0);
  const [milestonesState, setMilestonesState] = useState(0);
  const [dueMielstonesState, setDueMielstonesState] = useState(0);
  const {t,i18n} = useTranslation();

  useEffect(() => {
    setBudgetReceivedState(budgetReceived);
    setAdditionalBudgetReceivedState(additionalBudgetReceived);
    setMilestonesState(milestones);
    setDueMielstonesState(dueMielstones);
  }, []);
  return (
    <div className="flex flex-col items-start h-full">
      <p className="capitalize text-field-blue font-bold text-2xl mb-7">{t("generalStats")}</p>
      <p className="text-field-blue font-bold text-xl capitalize">
        {t("totalIncome")}: ${budgetReceived + additionalBudgetReceived}
      </p>
      <p className="text-field-blue font-bold text-xl capitalize">
        {t("milestones")}: {milestones}
      </p>
      <p className="text-field-blue font-bold text-xl capitalize">
       {t("dueMilestones")}: {dueMielstones}
      </p>
    </div>
  );
}
