import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";
import {
  startFetchMilestones as startFetchMilestonesSlice,
  successFetchMilestones as successFetchMilestonesSlice,
  errorFetchMilestones as errorFetchMilestonesSlice,
} from "../slices/milestones";

export const startFetchMilestones = createAction("START_FETCH_MILESTONES");
export const successFetchMilestones = createAction("SUCCESS_FETCH_MILESTONES");
export const errorFecthMilestones = createAction("ERROR_FETCH_MILESTONES");

export const fetchMilestones = (token,project_id) => async (dispatch) => {
    const requestOptions = {
        method: "GET",
        headers: {"Authorization":token}
    };
    try {
        dispatch(startFetchMilestonesSlice());  
        
        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects/"+project_id+"/milestones",requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects/"+project_id+"/milestones",requestOptions);
        }

        const data = await response.json();
        if (data.errors) {
            dispatch(errorFetchMilestonesSlice(data.errors));
        } else {
            dispatch(successFetchMilestonesSlice({data}))
        }
    } catch (error) {
        dispatch(errorFetchMilestonesSlice({error}))
    }
};