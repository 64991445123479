import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRegisteringIn: false,
  successRegisteringIn: false,
  errorRegisteringIn: false,
  registerData: {},
  errorRegisteringData: {},
};

const register = createSlice({
  name: "register",
  initialState,
  reducers: {
    startRegister(state, action) {
      state.isRegisteringIn = true;
      state.successRegisteringIn = false;
      state.errorRegisteringIn = false;
      state.registerData = {};
      state.errorRegisteringData = {};
    },
    succesRegister(state, action) {
      state.isRegisteringIn = false;
      state.successRegisteringIn = true;
      state.errorRegisteringIn = false;
      state.registerData = action.payload.data;
      state.errorRegisteringData = {};
    },
    errorRegister(state, action) {
      state.isRegisteringIn = false;
      state.successRegisteringIn = false;
      state.errorRegisteringIn = true;
      state.registerData = {};
      state.errorRegisteringData = action.payload.data;
    },
    registerReset(state,action){
      state.isRegisteringIn = false;
      state.successRegisteringIn = false;
      state.errorRegisteringIn = false;
      state.registerData = {};
    }
  },
});

const { actions, reducer } = register;

const { startRegister, succesRegister, errorRegister,registerReset } = actions;

export { startRegister, succesRegister, errorRegister, registerReset };

export default reducer;