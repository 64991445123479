import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isLoginIn:false,
    successLoginIn:false,
    errorLoginIn:false,
    logInErrorData:"",
    loggedIn:false,
    user_id:"",
    username:"",
    token:""
};

const loginIn = createSlice({
    name: "login-in",
    initialState,
    reducers: {
        startLoginIn(state,action){
            state.isLoginIn = true;
        },
        successLoginIn(state,action){
            state.successLoginIn = true;
            state.isLoginIn = false;
            state.user_id = action.payload.data.user_id;
            state.username = action.payload.data.username;
            state.token = action.payload.data.token;
            sessionStorage.setItem("token", action.payload.data.token);
            state.loggedIn = true;
        },
        errorLoginIn(state,action){
            state.loggedIn = false;
            state.errorLoginIn = true;
            state.logInErrorData = action.payload.data
        },
        logOut(state,action){
            state.loggedIn = false;
            state.user_id = ""
            state.username = "";
            state.token = "";
            sessionStorage.setItem("token", "");
        }
    }
});

const {actions,reducer} = loginIn;

const {
    startLoginIn,
    successLoginIn,
    errorLoginIn,
    logOut,
} = actions;

export {
    startLoginIn,
    successLoginIn,
    errorLoginIn,
    logOut,
};

export default reducer;