import automaticMenLogo from "../../assets/AutomaticMenTransparent.png";
import { languages } from "../../languages";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import i18next from "i18next";
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { useState } from "react";
import { FiGlobe } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import usFlag from "../../assets/us.svg";
import esFlag from "../../assets/es.svg";
import LangSelector from "../LangSelector";
export default function Footer() {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className="flex flex-col items-center">
      <img src={automaticMenLogo} className="w-20 h-20 self-center" />
      <p className="font-bold self-center">AutomaticMen 2022</p>
      <div>
        <LangSelector />
      </div>
    </div>
  );
}
