import { createAction } from "@reduxjs/toolkit";
import { apiLocation, apiLocalURLRoot, apiServerURLRoot } from "./apiconfig";
import {
  startRegister as startRegisterSlice,
  succesRegister as succesRegisterSlice,
  errorRegister as errorRegisterSlice,
  registerReset as registerResetSlice
} from "../slices/register";

export const startRegister = createAction("START_REGISTER");
export const succesRegister = createAction("SUCCESS_REGISTER");
export const errorRegister = createAction("ERROR_REGISTER");
export const registerReset = createAction("REGISTER_RESET");

export const register =
  ({ registerData }) =>
  async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        username: registerData.userName,
        email: registerData.email,
        password: registerData.password,
        password_confirmation: registerData.password,
      }),
    };

    try {
      dispatch(startRegisterSlice());

      let response;
      if (apiLocation === "LOCAL") {
        response = await fetch(apiLocalURLRoot + "users/",requestOptions);
      } else {
        response = await fetch(apiServerURLRoot + "users/",requestOptions);
      }
      const data = await response.json();

      if(data.status === "SUCCESS"){
        dispatch(succesRegisterSlice({data}));
      } else if (data.status === "ERROR")
      {
        dispatch(errorRegisterSlice({data}))
      }


    } catch (error) {
        dispatch(errorRegisterSlice({error}))
    }
  };
