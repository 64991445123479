import {
  inputFieldLabelStyle,
  inputFieldTextStyleNormal,
  cancelButtonStyle,
  acceptButtonStyle,
  } from "../../assets/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {milestoneCreate} from "../../redux/actions/milestonecreate";
import { useTranslation } from "react-i18next";

export default function AddClientModal({ setIsOpen, projectId }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  /**-----------------------------------------------------------------------------------------------
   * STATES HERE
   * ----------------------------------------------------------------------------------------------*/
  const [amount, setAmount] = useState();
  const [billNo, setBillNo] = useState();
  const [issueDate, setIssueDate] = useState();
  const [milestonStatus, setMilestonStatus] = useState(1);
  const [milestonType,setMilestonType] = useState(1);
  const [comments, setComments] = useState();

  /**-----------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ----------------------------------------------------------------------------------------------*/
  const handleCancelClick = () => {
    setIsOpen(false);
  };

  const handleSubmit = (event) => {
    const milestoneData = {
      project_id: 1,
      billNo: billNo,
      dateIssued: issueDate,
      amount: amount,
      milestone_status_id: milestonStatus,
      details: comments,
      milestone_types_id:milestonType
    };
    var tokenillo = sessionStorage.getItem("token");
    dispatch(milestoneCreate(tokenillo,projectId,milestoneData))
    event.preventDefault();
    setIsOpen(false); //TEMPORAL PARA QUE CIERRE LA VENTANA AQUI HAY QUE HACER EL REQUEST A LA API
  };

  const handleAmountChange = (event) => {
    setAmount(
      Number(event.target.value)
        .toFixed(2)
        .replace(/\d(?=(\d{10})+\.)/g, "$&,")
    );
  };

  const handleBillNoChange = (event) => {
    setBillNo(event.target.value);
  };

  const handleDateIssuedChange = (event) => {
    setIssueDate(event.target.value);
  };

  const handleMilestoneStatusChange = (event) => {
    event.target.value === "paid" ? setMilestonStatus(1) : setMilestonStatus(2)
  };

  const handleMilestoneTypeChange = (event)=>{
    event.target.value === "budget" ? setMilestonType(1) : setMilestonType(2)
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  /**-----------------------------------------------------------------------------------------------
   * RETURN HERE
   * ----------------------------------------------------------------------------------------------*/
  return (
    <div className="bg-black bg-opacity-70 flex h-screen w-screen items-center justify-center">
      <div className="border-2 bg-white rounded-lg flex flex-col items-center justify-center p-4">
        <p className="text-heading-blue font-bold m-4 text-3xl">
          {t("createMilestoneFormTitle")}
        </p>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-end space-y-2"
        >
          <label className={`${inputFieldLabelStyle}`}>
            {t("billNo")}
            <input
              type="text"
              required
              className={`${inputFieldTextStyleNormal} uppercase`}
              onChange={handleBillNoChange}
              value={billNo}
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            {t("dateIssued")}
            <input
              type="date"
              required
              className={`${inputFieldTextStyleNormal} uppercase`}
              onChange={handleDateIssuedChange}
              value={`${issueDate}`}
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            {t("amount")}
            <input
              type="number"
              required
              min="0.01"
              step="0.01"
              className={inputFieldTextStyleNormal}
              onChange={handleAmountChange}
              placeholder="0.00"
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            {t("milestoneType")}
            <select
              className={inputFieldTextStyleNormal}
              value={milestonType === 1?"budget":"additional"}
              onChange={handleMilestoneTypeChange}
            >
              <option value="budget">BUDGET</option>
              <option value="additional">ADDITIONAL</option>
            </select>
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            {t("milestoneStatus")}
            <select
              className={inputFieldTextStyleNormal}
              value={milestonStatus === 1?"paid":"unpaid"}
              onChange={handleMilestoneStatusChange}
            >
              <option value="paid">PAID</option>
              <option value="unpaid">UNPAID</option>
            </select>
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            <div className="flex">
              {t("comments")}
              <textarea
                className={`${inputFieldTextStyleNormal} h-32`}
                value={comments}
                onChange={handleCommentsChange}
              />
            </div>
          </label>
          <div className="flex flex-row py-10">
            <button className={cancelButtonStyle} onClick={handleCancelClick}>
              {t("cancel")}
            </button>
            <input className={acceptButtonStyle} type="submit" value={t("create")} />
          </div>
        </form>
      </div>
    </div>
  );
}
