import { FiEye } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import React from "react";
import ReactTooltip from "react-tooltip";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteProject } from "../../redux/actions/deleteproject";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function ProjectOperations({ projectName,project_id,hideControls }) {
  const { loggedIn, user_id, username, token, logInErrorData } = useSelector(
    (state) => state.loginInReducer
  );

  const{
    requestingProjectDeletion,
    errorRequestingProjectDeletion,
    successRequestingProjectDeletion,
    unauthorized
  } = useSelector((state)=>state.deleteProjectReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t,i18n} = useTranslation();


  const handleViewDetailsClick = () => {
    navigate("/details/"+project_id)
  };

  const handleProjectDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleProjectDelete = () => {
    var tokenillo = sessionStorage.getItem("token");
    setDeleteConfirmOpen(false);
    dispatch(deleteProject(tokenillo,project_id));

  };

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  return (
    <div className="flex flex-col h-full justify-center w-32 ">
      <ReactTooltip id="showProjectDetailsIcon" place="top" effect="solid">
        {t("details")}.
      </ReactTooltip>


{hideControls === false ? <FiEye
        data-tip
        data-for="showProjectDetailsIcon"
        className="h-10 w-10 mx-32 my-2 text-blue-600 hover:text-blue-400 cursor-pointer"
        onClick={handleViewDetailsClick}
      />:undefined}
      

      <ReactTooltip id="deleteProjectIcon" place="top" effect="solid">
        {t("deleteProject")}.
      </ReactTooltip>

{hideControls === false ? <FiX
        data-tip
        data-for="deleteProjectIcon"
        className="h-10 w-10 mx-32 my-2 text-red-500 hover:text-red-300 cursor-pointer"
        onClick={handleProjectDeleteClick}
      />:undefined}
      

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("deleteProjectMessageTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>{t("deleteProjectMessageBody")}</p>
            <p className="font-bold">{`${projectName}`}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleProjectDelete}>Yes</Button>
          <Button variant="outlined" onClick={handleDeleteCancel}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
