export const inputFieldLabelStyle = "uppercase font-bold text-field-blue text-l";
export const inputFieldTextStyleNormal = "border-2 border-field-blue p-1 active:border-field-blue ml-2 w-96";
export const inputFieldTextStyleError = "border-2 border-red-500 p-1 active:border-field-blue ml-2 w-96";
export const acceptButtonStyle = "capitalize bg-button-blue rounded-full text-white font-bold w-48 m-5 hover:bg-button-over-blue";
export const cancelButtonStyle = "bg-white rounded-full text-button-blue border-2 border-button-blue font-bold w-48 m-5 hover:bg-button-over-blue hover:text-white";
export const disabledButtonStyle = "bg-gray-400 rounded-full text-white border-2 border-button-blue font-bold w-48 m-5";
export const unpaidButtonStyle = "bg-pending-red rounded-full text-white border-2 border-button-blue font-bold w-48 m-2 hover:bg-unpaid-hover";
export const editButtonStyle = "bg-button-blue rounded-full text-white font-bold w-48 m-2 hover:bg-button-over-blue";
export const paidButtonStyle = "bg-active-green rounded-full text-white border-2 border-button-blue font-bold w-48 m-2 hover:bg-paid-hover";
export const navBarItemTextStyle = "uppercase font-bold text-field-blue text-2xl mx-2 cursor-pointer hover:text-button-over-blue"
export const navBarItemActiveStyle = "bg-field-blue h-2"
export const navBarItemUnActiveStyle = "bg-white"
export const projectCardCompletedStyle = "flex justify-center items-center bg-completed-blue text-white rounded-lg  uppercase font-bold w-8 h-48 mx-2 text-xs "
export const projectCardPendingStyle = "flex justify-center items-center bg-pending-red text-white rounded-lg  uppercase font-bold w-8 h-48 mx-2 text-xs align-middle indent-1"
export const projectCardActiveStyle = "flex justify-center items-center bg-active-green text-white rounded-lg  uppercase font-bold w-8 h-48 mx-2 text-xs align-middle indent-1"
export const projectCardOpportunityStyle = "flex justify-center items-center bg-opportunity-violet text-white rounded-lg uppercase font-bold w-8 h-48 mx-2 text-xs align-middle indent-1"
