import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";

import {
  requestMilestoneUpdate as requestMilestoneUpdateSlice,
  successMilestoneUpdate as successMilestoneUpdateSlice,
  errorMilestoneUpdate as errorMilestoneUpdateSlice,
} from "../slices/milestoneupdate";

export const requestMilestoneUpdate = createAction("REQUEST_MLESTONE_UPDATE");
export const successMilestoneUpdate = createAction("SUCCESS_MILESTONE_UPDATE");
export const errorMilestoneUpdate = createAction("ERROR_MILESTONE_UPDATE");

export const milestoneUpdate = (token,project_id,milestone_id,milestoneData) => async (dispatch)=>{
    const requestOptions = {
        method: "PUT",
        headers: {"Authorization": token,"Content-Type":"application/json"},
        body:JSON.stringify({
            project_id:project_id,
            billNo:milestoneData.billNo,
            dateIssued:milestoneData.dateIssued,
            amount:milestoneData.amount,
            milestone_status_id:milestoneData.milestone_status_id,
            details:milestoneData.details,
            milestone_types_id:milestoneData.milestone_types_id
            })
    }

    try {
        dispatch(requestMilestoneUpdateSlice());
        
        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects/"+project_id+"/milestones/"+milestone_id,requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects/"+project_id+"/milestones/"+milestone_id,requestOptions);
        }

        const data = await response.json();
        if (data.status === "SUCCESS") {
            dispatch (successMilestoneUpdateSlice({data}));
        }
        else{
            dispatch(errorMilestoneUpdateSlice(data.message));
        }
    } catch (error) {
        dispatch(errorMilestoneUpdateSlice({error}));
    }
};