import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { useState } from "react";
import { FiGlobe } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import usFlag from "../LangSelector/us.svg";
import esFlag from "../LangSelector/es.svg";
import i18next from "i18next";
import { useEffect } from "react";
export const languages = [
  {
    code: "en",
    name: "EN",
    country_code: "gb",
    flag: usFlag,
  },
  {
    code: "es",
    name: "ES",
    country_code: "es",
    flag: esFlag,
  },
];
export default function LangSelector() {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(languages[0].name);

  const handlGlobeClick = (event) => {
    setOpen(!open);
  };

  const handleListItemButtonClick = (event) => {
    if (event.target.children[0].name)
    {

      var language = event.target.children[0].name.toLowerCase();
      setLanguage(event.target.children[0].name)
      i18next.changeLanguage(language)
    }
    setOpen(!open);
  };

  const handleListItemTextClick = (event) => {
    var language = event.target.innerText.toLowerCase();
    setLanguage(event.target.innerText)
    i18next.changeLanguage(language)
    setOpen(!open);
  };

  const handleFlagImageClick = (event) => {
    var language = event.target.name.toLowerCase();
    setLanguage(event.target.name)
    i18next.changeLanguage(language)
    setOpen(!open);
  };

  useEffect(()=>{
    setLanguage(language)
  },[language])

  return (
    <div>
      <List sx={{ width: "100%", maxWidth: 100 }} component="nav">
        <ListItemButton sx={{ width:1, maxWidth: 100 }} onClick={handlGlobeClick}>
          <ListItemIcon >
            <FiGlobe className="w-7 h-7 mx-1" />
            <ListItemText primary={language} />
          </ListItemIcon>
          {open ? <FiChevronUp /> : <FiChevronDown />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {languages.map(({ code, name, country_code, flag }) => (
              <ListItemButton key={code} onClick={handleListItemButtonClick}>
                <ListItemIcon sx={{ width:25,height:25}}>
                  <img src={flag} onClick={handleFlagImageClick} name={name} code={code} alt={name}/>
                </ListItemIcon>
                <ListItemText primary={name} onClick={handleListItemTextClick} name={name} code={code}/>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );
}
