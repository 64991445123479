import NavBarItem from "./NavBarItem";
import { useTranslation } from 'react-i18next';





import FoldableMenu from "../FoldableMenu";




export default function NavBar({activeTab}) {
    const { t, i18n } = useTranslation();
    return (
        <div className="flex self-center m-4 flex-row">
            <NavBarItem title={t("dashboard")} url="/dashboard" active={activeTab} visible ={true}/>
            <NavBarItem title={t("new")}  url="/new-project" active={activeTab} visible ={false}/>
            <NavBarItem title={t("active")} url="/active" active={activeTab} visible ={true}/>
            <NavBarItem title={t("pending")} url="/pending" active={activeTab} visible ={true}/>
            <NavBarItem title={t("completed")} url="/completed"active={activeTab} visible ={true}/>
            <NavBarItem title={t("opportunity")} url="/opportunity"active={activeTab} visible ={true}/>
            <NavBarItem title={t("details")} url="/details" active={activeTab} visible ={false}/>
        </div>
    );
}