import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isFecthingAllProjectsData: false,
  isLoadingAllProjectsData: false,
  errorFetchingAllProjects: false,
  successFetchingAllProjects: true,
  unauthorized:true,
  projectsData: {},
};

const allProjects = createSlice({
  name: "all-proects",
  initialState,
  reducers: {
    startFetchAllProjectesData(state, action) {
      state.isLoadingAllProjectsData = false;
      state.isFecthingAllProjectsData = true;
    },
    sucessFetchAllProjectsData(state, action) {
      state.isLoadingAllProjectsData = false;
      state.isFecthingAllProjectsData = false;
      state.projectsData = action.payload.data;
      state.sucessFetchAllProjectsData = true;
      state.errorFetchingAllProjects = null;
      state.unauthorized = false;
    },
    errorFetchAllProjects(state, action) {
      state.isLoadingAllProjectsData = false;
      state.isFecthingAllProjectsData = false;
      state.projectsData = {};
      state.errorFetchingAllProjects = action.payload.data;
      state.unauthorized = true;
    },
    startClearAllProjectsData(state, action) {
      state.projectsData = {};
    },
  },
});

const { actions, reducer } = allProjects;

const {
  startFetchAllProjectesData,
  sucessFetchAllProjectsData,
  errorFetchAllProjects,
  startClearAllProjectsData,
} = actions;

export {
  startFetchAllProjectesData,
  sucessFetchAllProjectsData,
  errorFetchAllProjects,
  startClearAllProjectsData,
};

export default reducer;
