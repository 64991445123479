import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assets/LoginPageBackground.jpg";
import Footer from "../../components/Footer";
import AddMilestoneModal from "../AddMilestoneModal";
import EditMilestoneModal from "../EditMilestoneModal";
import { useDispatch, useSelector } from "react-redux";
import {logUserIn} from "../../redux/actions/login"
import { useTranslation } from 'react-i18next';

export default function LoginPage() {
  /**CONSTS HERE */
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
  isLoginIn,
  successLoginIn,
  errorLoginIn,
  loggedIn,
  user_id,
  username,
  token
} = useSelector((state)=>state.loginInReducer);
  //STATES
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
  const [isUsernameEmpty, setIsUserNameEmpty] = useState(false)

  const [isOpen, setIsOpen] = useState(false);

  /**-----------------------------------------------------
   * USE EFFECTS HERE
   ------------------------------------------------------*/
   useEffect(()=>{
    if (loggedIn === true){
      navigate("/dashboard")
    }
   },[loggedIn])

  /**-----------------------------------------------------
   * Handlers here
   * -----------------------------------------------------
   */

  //Log In Handler
  const handleLogInClick = () => {
    if (email.length == 0 || password.length == 0) {
      alert("Username or Password can´t be empty ...");
    } else {
      //AQUI IRAN LOS LLAMADOS A LA API PARA LOGUEARSE ETC ETC DE MOMENTO NOS VAMOS AL DASHBOARD
      dispatch(logUserIn({email,password}))
    }
  };

  //Sign In CLick Handler
  const handleSignInClick = () => {
    navigate("/register")
  };

  //Username Change Handler
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  //Password Change handler
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  if (isOpen) {
  }
  return (
    //DIV CONTAINER FOR THE WHOLE PAGE. I AM ADDING THE BACKGROUND IMAGE HERE
    <div>

      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          opacity: "1",
        }}
        className="w-screen h-screen flex flex-col justify-center"
      >
        <div
          className="bg-white flex-col m-20 drop-shadow-2xl self-center"
          style={{ width: "668px", height: "508px" }}
        >
          <div>
            <h1 className="text-heading-blue font-bold m-16 text-3xl">
              {t("Welcome to Simple Project Manager")}
            </h1>
          </div>

          <div className="flex flex-col m-8">
            <div className="m-2">
              <label className="uppercase font-bold text-field-blue text-xl ">
                {t("email")}
                <input
                  type="text"
                  className={`border-2 border-field-blue p-1 active:border-field-blue ml-2`}
                  placeholder={t("type your username ...")}
                  onChange={handleEmailChange}
                />
              </label>
            </div>

            <div className="m-2">
              <label className="uppercase font-bold text-field-blue text-xl">
                {t("password")}
                <input
                  type="password"
                  className="border-2 border-field-blue p-1 active:border-field-blue ml-2"
                  placeholder=" type your password"
                  onChange={handlePasswordChange}
                />
              </label>
            </div>
          </div>
          <div>
            <button
              onClick={handleLogInClick}
              className="bg-button-blue rounded-full text-white font-bold w-48 m-5 hover:bg-button-over-blue"
            >
              {t("Log In")}
            </button>
          </div>
          <div>
            <hr className="m-8 border-1 border-black" />
          </div>
          <div>
            <p>{t("Not using it already?")}</p>
            <p
              className="text-button-blue cursor-pointer"
              onClick={handleSignInClick}
            >
              {t("Sign In")}
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
