import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestingMilestoneUpdate: false,
  successRequestingMilestoneUpdate: false,
  errorRequestingMilestoneUpdate: false,
  errorRequestingMilestoneUpdateData: "",
  milestoneData: {},
  unauthorized: true,
};

const milestoneUpdate = createSlice({
  name: "milestone-update",
  initialState,
  reducers: {
    requestMilestoneUpdate(state, action) {
      state.requestingMilestoneUpdate = true;
      state.successRequestingMilestoneUpdate = false;
      state.errorRequestingMilestoneUpdate = false;
      state.errorRequestingMilestoneUpdateData = "";
      state.milestoneData = {};
      state.unauthorized = true;
    },
    successMilestoneUpdate(state, action) {
      state.requestingMilestoneUpdate = false;
      state.successRequestingMilestoneUpdate = true;
      state.errorRequestingMilestoneUpdate = false;
      state.errorRequestingMilestoneUpdateData = "";
      state.milestoneData = action.payload.data;
      state.unauthorized = false;
    },
    errorMilestoneUpdate(state, action) {
      state.requestingMilestoneUpdate = false;
      state.successRequestingMilestoneUpdate = false;
      state.errorRequestingMilestoneUpdate = true;
      state.errorRequestingMilestoneUpdateData = action.payload.data;
      state.milestoneData = {};
      state.unauthorized = false;
    },
    clearMilestoneState(state, action) {
      state.requestingMilestoneUpdate = false;
      state.successRequestingMilestoneUpdate = false;
      state.errorRequestingMilestoneUpdate = false;
      state.errorRequestingMilestoneUpdateData = "";
      state.milestoneData = {};
      state.unauthorized = false;
    },
  },
});

const { actions, reducer } = milestoneUpdate;

const { requestMilestoneUpdate, successMilestoneUpdate, errorMilestoneUpdate, clearMilestoneState } = actions;

export { requestMilestoneUpdate, successMilestoneUpdate, errorMilestoneUpdate, clearMilestoneState };

export default reducer;