import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestingMilestoneCreate: false,
  successRequestingMilestoneCreate: false,
  errorRequestingMilestoneCreate: false,
  errorRequestingMIlestoneCreateData: {},
  milestoneData: {},
  unauthorized: true,
};

const milestoneCreate = createSlice({
  name: "milestone-create",
  initialState,
  reducers: {
    requestMilestoneCreate(state, action) {
      state.requestingMilestoneCreate = true;
      state.successRequestingMilestoneCreate = false;
      state.errorRequestingMilestoneCreate = false;
      state.errorRequestingMIlestoneCreateData = "";
      state.milestoneData = {};
      state.unauthorized = true;
    },
    successMilestoneCreate(state, action) {
      state.requestingMilestoneCreate = false;
      state.successRequestingMilestoneCreate = true;
      state.errorRequestingMilestoneCreate = false;
      state.errorRequestingMIlestoneCreateData = "";
      state.milestoneData = action.payload.data;
      state.unauthorized = false;
    },
    errorMilestoneCreate(state, action) {
      state.requestingMilestoneCreate = false;
      state.successRequestingMilestoneCreate = false;
      state.errorRequestingMilestoneCreate = true;
      state.errorRequestingMIlestoneCreateData = action.payload.data;
      state.milestoneData = {};
      state.unauthorized = false;
    },
  },
});

const { actions, reducer } = milestoneCreate;

const { requestMilestoneCreate, successMilestoneCreate, errorMilestoneCreate } = actions;

export { requestMilestoneCreate, successMilestoneCreate, errorMilestoneCreate };

export default reducer;