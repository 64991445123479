import {
  inputFieldLabelStyle,
  inputFieldTextStyleNormal,
  cancelButtonStyle,
  acceptButtonStyle,
  disabledButtonStyle,
} from "../../assets/styles";
import { useState } from "react";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { milestoneUpdate } from "../../redux/actions/milestoneupdate";
import { useTranslation } from "react-i18next";

export default function EditMilestoneModal({
  setIsOpen,
  curentBillNo,
  currentIssueDate,
  currentAmount,
  currentMilestonStatus,
  currentComments,
  milestoneId, //lo vamos a utilizar para la solicitud de actualizacion a la API
  projectId, // Para pasarle el proyecto al cual pertenece
}) {
  const dispatch = useDispatch();
  const {t,i18n} = useTranslation();
  const {
    requestingMilestoneUpdate,
    successRequestingMilestoneUpdate,
    errorRequestingMilestoneUpdate,
    errorRequestingMilestoneUpdateData,
    milestoneData,
    unauthorized,
  } = useSelector((state) => state.milestoneUpdateReducer);

  //Will use them to see if the user is logged in or not.
  const { loggedIn, user_id, username, token, logInErrorData } = useSelector(
    (state) => state.loginInReducer
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn === false) {
      navigate("/");
    }
  }, [loggedIn]);

  /**-----------------------------------------------------------------------------------------------
   * STATES HERE
   * ----------------------------------------------------------------------------------------------*/
  const [amount, setAmount] = useState(currentAmount);
  const [billNo, setBillNo] = useState(curentBillNo);
  const [issueDate, setIssueDate] = useState(currentIssueDate);
  const [milestonStatus, setMilestonStatus] = useState(currentMilestonStatus);
  const [comments, setComments] = useState(currentComments);
  const [isDataEdited, setIsDataEdited] = useState(false); //Para si no han editado ningun campo desactivar el boton de salvar

  /**-----------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ----------------------------------------------------------------------------------------------*/
  const handleCancelClick = () => {
    setIsOpen(false);
  };

  const handleSubmit = (event) => {
    var tokenillo = sessionStorage.getItem("token");
    event.preventDefault();
    const milestoneData = {
      project_id: 1,
      billNo: billNo,
      dateIssued: issueDate,
      amount: amount,
      milestone_status_id: milestonStatus,
      details: comments,
    };
    setIsOpen(false); //TEMPORAL PARA QUE CIERRE LA VENTANA AQUI HAY QUE HACER EL REQUEST A LA API
    dispatch(milestoneUpdate(tokenillo, projectId, milestoneId, milestoneData));
  };

  const handleAmountChange = (event) => {
    if (event.target.value == currentAmount) {
      setIsDataEdited(false);
    } else {
      setIsDataEdited(true);
    }
    setAmount(
      Number(event.target.value)
        .toFixed(2)
        .replace(/\d(?=(\d{10})+\.)/g, "$&,")
    );
  };

  const handleBillNoChange = (event) => {
    if (event.target.value == curentBillNo) {
      setIsDataEdited(false);
    } else {
      setIsDataEdited(true);
    }
    setBillNo(event.target.value);
  };

  const handleDateIssuedChange = (event) => {
    if (event.target.value == currentIssueDate) {
      setIsDataEdited(false);
    } else {
      setIsDataEdited(true);
    }
    setIssueDate(event.target.value);
  };

  const handleMilestoneStatusChange = (event) => {
    if (event.target.value == currentMilestonStatus) {
      setIsDataEdited(false);
    } else {
      setIsDataEdited(true);
    }
    switch (event.target.value) {
      case "paid":
        setMilestonStatus(1);
        break;

      case "unpaid":
        setMilestonStatus(2);
        break;

      default:
        break;
    }
  };

  const handleCommentsChange = (event) => {
    if (event.target.value == currentComments) {
      setIsDataEdited(false);
    } else {
      setIsDataEdited(true);
    }
    setComments(event.target.value);
  };

  /**-----------------------------------------------------------------------------------------------
   * RETURN HERE
   * ----------------------------------------------------------------------------------------------*/
  return (
    <div className="bg-black bg-opacity-70 h-screen w-screen flex items-center justify-center">
      <div className="border-2 bg-white rounded-lg flex flex-col items-center justify-center p-4">
        <p className="text-heading-blue font-bold mt-4 text-3xl capitalize">
          {t("EditMilestoneWindowTitle")}
        </p>
        <p className="mb-4">
          {" "}
          ({curentBillNo} , {currentIssueDate} , {currentAmount} ,{" "}
          {currentMilestonStatus})
        </p>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-end space-y-2"
        >
          <label className={`${inputFieldLabelStyle}`}>
            {t("billNo")}
            <input
              type="text"
              required
              className={`${inputFieldTextStyleNormal} uppercase`}
              onChange={handleBillNoChange}
              value={billNo}
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            {t("dateIssued")}
            <input
              type="date"
              required
              className={`${inputFieldTextStyleNormal} uppercase`}
              onChange={handleDateIssuedChange}
              value={`${issueDate}`}
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            {t("amount")}
            <input
              type="number"
              required
              min="0.01"
              step="0.01"
              className={inputFieldTextStyleNormal}
              onChange={handleAmountChange}
              placeholder="0.00"
              defaultValue={currentAmount}
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
          {t("milestoneStatus")}
            <select
              className={inputFieldTextStyleNormal}
              onChange={handleMilestoneStatusChange}
              value={`${milestonStatus==1?"paid":"unpaid"}`}
            >
              <option  value="paid">{t("paid")}</option>
              <option  value="unpaid">{t("unpaid")}</option>
            </select>
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            <div className="flex">
              {t("comments")}
              <textarea
                className={`${inputFieldTextStyleNormal} h-32`}
                value={comments}
                onChange={handleCommentsChange}
              />
            </div>
          </label>
          <div className="flex flex-row py-10">
            <button className={cancelButtonStyle} onClick={handleCancelClick}>
              {t("cancel")}
            </button>
            {isDataEdited ? (
              <input className={acceptButtonStyle} type="submit" value={t("save")} />
            ) : (
              <input
                className={disabledButtonStyle}
                type="submit"
                value={t("save")}
                disabled
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
