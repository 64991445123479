import TitleBanner from "../../components/TitleBanner";
import ClientNavBar from "../../components/ClientNavBar";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import ClientSummaryCard from "../../components/ClientSummaryCard";
import Grid from "@mui/material/Grid";
import { fetchClients } from "../../redux/actions/clients";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField, Container } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";

import ClientAddCard from "../../components/ClientAddCard";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

export default function ClientsDashboardPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isFecthingAllClientsData,
    isLoadingAllClientsData,
    errorFetchingAllClients,
    successFetchingAllClients,
    unauthorized,
    clientsData,
  } = useSelector((state) => state.clientsReducer);

  const { loggedIn, user_id, username, token, logInErrorData } = useSelector(
    (state) => state.loginInReducer
  );

  const [filteredClients, setFilteredClients] = useState();
  const [isClientsFound,setIsClientsFound] = useState(true);

  const handleSearchBoxChange = (event) => {
    const foundClients = clientsData.data.filter(function (client) {
      return (
        client[0].firstName
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        client[0].lastName
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
    });
    setFilteredClients({ data: foundClients });
    foundClients.length===0?setIsClientsFound(false):setIsClientsFound(true);
    console.log(clientsData)
    console.log(foundClients);
  };

  useEffect(() => {
    var tokenillo = sessionStorage.getItem("token");
    dispatch(fetchClients(tokenillo));
  }, []);

  useEffect(() => {
    setFilteredClients(clientsData);
  }, [clientsData]);

  if (unauthorized) {
    return "UNAUTHORIZED";
  } else {
  }
  return (
    <div className="flex flex-col self-center">
      <TitleBanner title={t("clientDashboardTitle")} />
      <ClientNavBar activeTab={t("dashboard")} />
      
      <Box
        sx={{ display: "flex", alignItems: "center", alignSelf: "center" }}
        marginTop={2}
      >
        <TextField
          id="input-with-sx"
          placeholder="client name"
          variant="outlined"
          size="medium"
          onChange={handleSearchBoxChange}
        />
        <SearchIcon
          sx={{ color: "action.active", width: "32px", height: "32px" }}
        />
      </Box>
      
      { isClientsFound===true?
      undefined:(
        <Container maxWidth="xs">
        {t("noClientsFound")}
      </Container>
      )

      }
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        marginTop={2}
      >
        {filteredClients?.data?.map((value) => {
          return (
            <Grid item>
              <ClientSummaryCard
                name={value[0].firstName + " " + value[0].lastName}
                email={value[0].email}
                phone={value[0].phone}
                address={value[0].address}
                activeCount={value[1].activeCount}
                pendingCount={value[2].pendingCount}
                opportunityCount={value[3].opportunityCount}
                completedCount={value[4].completedCount}
                clientDetails={value[0].details}
              />
            </Grid>
          );
        })}
        <Grid item>
          <ClientAddCard/>
            </Grid>
      </Grid>
      <div className="m-20">
        <Footer />
      </div>
    </div>
  );
}
