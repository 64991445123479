import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import { Avatar } from "@mui/material";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import { green, deepPurple, lightBlue, red } from "@mui/material/colors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { IconButton } from "@mui/material";
import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const summaryCardSecondaryDataTextColor = "#8B8181"

export default function ClientAddCard({
  name,
  email,
  phone,
  address,
  activeCount,
  pendingCount,
  opportunityCount,
  completedCount,
  clientDetails,
}) {
    const [expanded, setExpanded] = useState(false);
    const [clientSummaryCardHeight,setClientSummaryCardHeight] = useState(300);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const { t, i18n } = useTranslation();


  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight={345}
      sx={{
        width: 550,
        height: 345,
        boxShadow: "0px 0px 15px black ",
        borderRadius: "20px",
      }}
    >


      <div className="flex-col">
          <div>
            <IconButton>
              <FiPlusCircle
                className="rounded-full text-4xl text-bold bg-button-blue w-20 h-20 text-white hover:bg-button-over-blue"
              />
            </IconButton>
          </div>
          <p className="font-bold text-field-blue text-xl">
            {t("addNewClient")}
          </p>
        </div>
        
      
      
    </Box>
  );
}
