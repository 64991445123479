import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isFecthingAllClientsData: false,
  isLoadingAllClientsData: false,
  errorFetchingAllClients: false,
  successFetchingAllClients: true,
  unauthorized:true,
  clientsData: {},
};

const allProjects = createSlice({
  name: "all-clients",
  initialState,
  reducers: {
    startFetchAllClientsData(state, action) {
      state.isLoadingAllClientsData = false;
      state.isFecthingAllClientsData = true;
    },
    sucessFetchAllClientsData(state, action) {
      state.isLoadingAllClientsData = false;
      state.isFecthingAllClientsData = false;
      state.clientsData = action.payload.data;
      state.sucessFetchAllProjectsData = true;
      state.errorFetchingAllClients = null;
      state.unauthorized = false;
    },
    errorFetchAllClients(state, action) {
      state.isLoadingAllClientsData = false;
      state.isFecthingAllClientsData = false;
      state.clientsData = {};
      state.errorFetchingAllClients = action.payload.data;
      state.unauthorized = true;
    },
    startClearAllClientsData(state, action) {
      state.projectsData = {};
    },
  },
});

const { actions, reducer } = allProjects;

const {
  startFetchAllClientsData,
  sucessFetchAllClientsData,
  errorFetchAllClients,
  startClearAllClientsData,
} = actions;

export {
  startFetchAllClientsData,
  sucessFetchAllClientsData,
  errorFetchAllClients,
  startClearAllClientsData,
};

export default reducer;
