import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestingMilestoneDelete: false,
  errorRequestingMilestoneDelete: false,
  successRequestingMilestoneDelete: false,
  errorRequestingMilestoneDeleteData: {},
  unauthorized: true,
};

const milestoneDelete = createSlice({
  name: "milestone-delete",
  initialState,
  reducers: {
    requestMilestoneDelete(state, action) {
      state.requestingMilestoneDelete = true;
      state.errorRequestingMilestoneDelete = false;
      state.successRequestingMilestoneDelete = false;
      state.errorRequestingMilestoneDeleteData = {};
      state.unauthorized = true;
    },
    successMilestoneDelete(state, action) {
      state.requestingMilestoneDelete = false;
      state.errorRequestingMilestoneDelete = false;
      state.successRequestingMilestoneDelete = true;
      state.errorRequestingMilestoneDeleteData = {};
      state.unauthorized = false;
    },
    errorMilestoneDelete(state, action) {
      state.requestingMilestoneDelete = false;
      state.errorRequestingMilestoneDelete = true;
      state.successRequestingMilestoneDelete = false;
      state.errorRequestingMilestoneDeleteData = action.payload.data;
      state.unauthorized = false;
    },
  },
});

const { actions, reducer } = milestoneDelete;
const { requestMilestoneDelete, successMilestoneDelete, errorMilestoneDelete } = actions;
export { requestMilestoneDelete, successMilestoneDelete, errorMilestoneDelete };
export default reducer;
