import {
  unpaidButtonStyle,
  acceptButtonStyle,
  paidButtonStyle,
  editButtonStyle,
} from "../../assets/styles";
import { useState } from "react";
import EditMilestoneModal from "../EditMilestoneModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { milestoneUpdate } from "../../redux/actions/milestoneupdate";
import Loading from "../../components/Loader";
import { fetchMilestones } from "../../redux/actions/milestones";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearMilestoneState } from "../../redux/slices/milestoneupdate";
import { milestoneDelete } from "../../redux/actions/milestonedelete";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function Milestone({
  setIsOpen,
  billNo,
  issueDate,
  amount,
  milestoneStatus,
  comments,
  milestoneId,
  projectId,
}) {
  /**-----------------------------------------------------------------------------------------------
   * CONSTANTS AND HOOKS HERE
   * ----------------------------------------------------------------------------------------------*/
  const {t,i18n}= useTranslation();

  /**-----------------------------------------------------------------------------------------------
   * STATES HERE
   * ----------------------------------------------------------------------------------------------*/
  const [
    isErrorRequestingMilestoneUpdate,
    setIsErrorRequestingMilestoneUpdate,
  ] = useState(false);

  const [isEditMilestoneModalOpen, setIsEditMilestoneModalOpen] =
    useState(false);

  const [milestoneStat, setMilestoneStat] = useState(milestoneStatus);

  const [deleteMilestoneDialogOpen, setDeleteMilestoneDialogOpen] =
    useState(false);

  const [isRequestingMilestoneUpdate, setIsRequestingMilestoneUpdate] =
    useState(false);

  const [
    isSuccessRequestingMilestoneUpdate,
    setIsSuccessRequestingMilestoneUpdate,
  ] = useState(false);

  /**-----------------------------------------------------------------------------------------------
   * STATE SELECTORS HERE
   * ----------------------------------------------------------------------------------------------*/
  const {
    requestingMilestoneUpdate,
    successRequestingMilestoneUpdate,
    errorRequestingMilestoneUpdate,
    errorRequestingMilestoneUpdateData,
    milestoneData,
    unauthorized,
  } = useSelector((state) => state.milestoneUpdateReducer);

  const dispatch = useDispatch();

  /**-----------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ----------------------------------------------------------------------------------------------*/
  useEffect(() => {
    if (requestingMilestoneUpdate === true) {
      setIsRequestingMilestoneUpdate(false);
    }
  }, [requestingMilestoneUpdate]);

  useEffect(() => {
    if (successRequestingMilestoneUpdate === true) {
      setIsSuccessRequestingMilestoneUpdate(true);
    }
  }, [successRequestingMilestoneUpdate]);

  useEffect(() => {
    if (errorRequestingMilestoneUpdate === true) {
      setIsErrorRequestingMilestoneUpdate(true);
    }
  }, [errorRequestingMilestoneUpdate]);

  useEffect(() => {
    setIsSuccessRequestingMilestoneUpdate(false);
    switch (milestoneStatus) {
      case 1:
        setMilestoneStat("paid");
        break;

      case 2:
        setMilestoneStat("unpaid");
        break;

      default:
        alert("Unvalid Milestone Stauts");
        break;
    }
  }, []);

  /**------------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ----------------------------------------------------------------------------------------------*/
  const handleDeleteMilestoneDialogClose = () => {
    setDeleteMilestoneDialogOpen(false);
  };

  const handleDeleteMilestoneDialogOpen = () => {
    setDeleteMilestoneDialogOpen(true);
  };
  const handleEditClick = () => {
    setIsEditMilestoneModalOpen(true);
  };

  const handleMarkPaidClick = () => {
    //AQUUI POR SUPUESTO VA LA LLAMADA A LA API DE UPDATE EL ESTADO ESTO ES SOLO PARA MOSTRAR
    setIsRequestingMilestoneUpdate(true);
    setMilestoneStat("unpaid");
    const milestoneData = {
      milestone_status_id: 2,
    };
    var tokenillo = sessionStorage.getItem("token");
    dispatch(milestoneUpdate(tokenillo, projectId, milestoneId, milestoneData));
  };

  const handleMarkUnpaidClick = () => {
    //AQUUI POR SUPUESTO VA LA LLAMADA A LA API DE UPDATE EL ESTADO ESTO ES SOLO PARA MOSTRAR
    setIsRequestingMilestoneUpdate(true);
    setMilestoneStat("paid");
    const milestoneData = {
      milestone_status_id: 1,
    };
    var tokenillo = sessionStorage.getItem("token");
    dispatch(milestoneUpdate(tokenillo, projectId, milestoneId, milestoneData));
  };

  const handleDeleteClick = () => {
    var tokenillo = sessionStorage.getItem("token");
    dispatch(milestoneDelete(tokenillo, projectId, milestoneId));
    setDeleteMilestoneDialogOpen(false);
  };

  /**---------------------------------------------------------------------------------------------
   * RENDERING HERE
   ----------------------------------------------------------------------------------------------*/
  const renderMilestoneView = () => (
    <div className="flex space-x-2  w-full rounded-xl hover:bg-slate-300">
      <div
        className={`${
          milestoneStat == "paid" ? "bg-active-green" : "bg-pending-red"
        } w-2 rounded-xl ml-2`}
      />
      <div className="flex flex-col items-start w-4/12">
        <p className="text-field-blue font-bold text-xl">{t("billNo")}: {billNo}</p>
        <p className="text-field-blue font-bold text-xl">
          {t("dateIssued")}: {issueDate}
        </p>
        <p className="text-field-blue font-bold text-xl">{t("amount")}: ${amount}</p>
        <p className="text-field-blue font-bold text-xl">
          {t("milestoneStatus")}: {milestoneStatus == 1 ? `${t("paid")}` : `${t("unpaid")}`}
        </p>
      </div>
      <div className="flex w-8/12">
        <p className="text-field-blue font-bold text-xl text-justify">
          {comments}
        </p>
      </div>
      <div className="flex flex-col place-items-start">
        {milestoneStat == "paid" ? (
          <button className={unpaidButtonStyle} onClick={handleMarkPaidClick}>
            {t("markUnpaid")}
          </button>
        ) : (
          <button className={paidButtonStyle} onClick={handleMarkUnpaidClick}>
            {t("markPaid")}
          </button>
        )}
        <button className={editButtonStyle} onClick={handleEditClick}>
          {t("edit")}
        </button>
        <button
          className={editButtonStyle}
          onClick={handleDeleteMilestoneDialogOpen}
        >
          {t("delete")}
        </button>
      </div>
    </div>
  );

  const renderMilestoneUpdateLoader = () => (
    <Loading title="Updating Milestone" body="Please Wait ..." />
  );

  const succesMilestonUpdateToast = () => {
    setIsSuccessRequestingMilestoneUpdate(false);
    toast.success("Milestone Updated Succesfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    toast.clearWaitingQueue();
    var tokenillo = sessionStorage.getItem("token");
    dispatch(fetchMilestones(tokenillo, projectId));
  };

  const errorMilestoneUpdateToast = () => {
    setIsErrorRequestingMilestoneUpdate(false);
    toast.error("Milestone Did Not Update Succesfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    toast.clearWaitingQueue();
  };

  /**---------------------------------------------------------------------------------------------
   * RETURN SECTION HERE.
   ----------------------------------------------------------------------------------------------*/
  return (
    <div>
      {isRequestingMilestoneUpdate
        ? renderMilestoneUpdateLoader()
        : renderMilestoneView()}

      {isSuccessRequestingMilestoneUpdate === true
        ? succesMilestonUpdateToast()
        : undefined}

      {isErrorRequestingMilestoneUpdate === true
        ? errorMilestoneUpdateToast()
        : undefined}

      <div style={{ position: "fixed", left: 0, top: 0 }}>
        {isEditMilestoneModalOpen && (
          <EditMilestoneModal
            setIsOpen={setIsEditMilestoneModalOpen}
            curentBillNo={billNo}
            currentAmount={amount}
            currentIssueDate={issueDate}
            currentMilestonStatus={milestoneStatus}
            currentComments={comments}
            milestoneId={milestoneId}
            projectId={projectId}
          />
        )}
      </div>

      <Dialog
        open={deleteMilestoneDialogOpen}
        onClose={handleDeleteMilestoneDialogClose}
        aria-labelledby="delete-milestone-alert-dialog-title"
        aria-describedby="delete-milestone-alert-dialog-description"
      >
        <DialogTitle id="delete-milestone-alert-dialog-title">
          {t("deleteMilestoneMessageTitle")}: {billNo}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-milestone-alert-dialog-description">
            {t("deleteMilestoneMessageBody")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDeleteClick}>
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteMilestoneDialogClose}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
