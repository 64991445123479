import TitleBanner from "../TitleBanner";
import Footer from "../Footer";
export default function FourOFour(params) {
  return (
    <div className="flex flex-col self-center h-screen justify-between">
      <TitleBanner title={"page not found"} />
      <p className="text-2xl m-20">
        We are sorry but it seems that the page you are looking for does not
        exist...
      </p>

      <div className="m-20 ">
        <Footer />
      </div>
    </div>
  );
}
