import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestingProjectCreation: false,
  errorRequestingProjectCreation: false,
  successCreatingNewProject: false,
  unauthorized: true,
};

const createProject = createSlice({
  name: "create-project",
  initialState,
  reducers: {
    requestProjectCreation(state, action) {
      state.requestingProjectCreation = true;
      state.errorRequestingProjectCreation = false;
      state.successCreatingNewProject = false;
      state.unauthorized = true;
    },
    successProjectCreation(state, action) {
      state.requestingProjectCreation = false;
      state.errorRequestingProjectCreation = false;
      state.successCreatingNewProject = true;
      state.unauthorized = false;
    },
    errorProjectCreation(state, action) {
      state.requestingProjectCreation = false;
      state.errorRequestingProjectCreation = true;
      state.successCreatingNewProject = false;
      state.unauthorized = true;
    },
    newProjectClear(state, action) {
      state.requestingProjectCreation = false;
      state.errorRequestingProjectCreation = false;
      state.successCreatingNewProject = false;
      state.unauthorized = true;
    }
  },
});

const { actions, reducer } = createProject;

const { requestProjectCreation, successProjectCreation, errorProjectCreation, newProjectClear } = actions;

export { requestProjectCreation, successProjectCreation, errorProjectCreation, newProjectClear };

export default reducer;
