import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingMilestones: true,
  succesFetchingMilestones: false,
  milestonesData: {},
  errorFetchingMilestones: false,
  errorFetchingMilestonesData: "",
  unauthorized: true,
};

const allMilestones = createSlice({
  name: "all-milestones",
  initialState,
  reducers: {
    startFetchMilestones(state, action) {
      state.isFetchingMilestones = true;
      state.succesFetchingMilestones = false;
      state.milestonesData = {};
      state.errorFetchingMilestones = false;
      state.errorFetchingMilestonesData = "";
      state.unauthorized = true;
    },
    successFetchMilestones(state, action) {
      state.isFetchingMilestones = false;
      state.succesFetchingMilestones = true;
      state.milestonesData = action.payload.data;
      state.errorFetchingMilestones = false;
      state.errorFetchingMilestonesData = "";
      state.unauthorized = false;
    },
    errorFetchMilestones(state, action) {
      state.isFetchingMilestones = false;
      state.succesFetchingMilestones = false;
      state.milestonesData = {};
      state.errorFetchingMilestones = true;
      state.errorFetchingMilestonesData = action.payload.data;
      state.unauthorized = true;
    },
  },
});

const { actions, reducer } = allMilestones;

const { startFetchMilestones, successFetchMilestones, errorFetchMilestones } =
  actions;

export { startFetchMilestones, successFetchMilestones, errorFetchMilestones };

export default reducer;
