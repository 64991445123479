import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";
import {
    startFetchAllProjectesData as startFetchAllProjectesDataSlice,
    sucessFetchAllProjectsData as sucessFetchAllProjectsDataSlice,
    errorFetchAllProjects as errorFetchAllProjectsSlice,
    startClearAllProjectsData as startClearAllProjectsDataSlice,
  } from "../slices/projects.js"

export const startFetchAllProjectesData = createAction("START_FETCH_ALL_PROJECTS_DATA"); 
export const sucessFetchAllProjectsData = createAction("SUCCESS_FETCH_ALL_PROJECTS_DATA");
export const errorFetchAllProjects = createAction("ERROR_FETCH_ALL_PROJECTS");
export const startClearAllProjectsData = createAction("START_CLEAR_ALL_PROJECTS_DATA");

export const fetchProjects = (token)=>async (dispatch) => {
    const requestOptions = {
        method: "GET",
        headers: {"Authorization": token},
    }
    try {
        dispatch(startFetchAllProjectesDataSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects",requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects",requestOptions);
        }
        const data = await response.json();
        if(data.errors){
            dispatch(errorFetchAllProjectsSlice(data.errors));
        }else
        {
            dispatch (sucessFetchAllProjectsDataSlice({data}));
        }
    } catch (error) {
        dispatch(errorFetchAllProjectsSlice({error}));
    }
};