import { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar";
import { useTranslation } from 'react-i18next';

export default function BudgetSummary({
  budgetTotal,
  budgetReceived,
  budgetPending,
}) {
  const [budgetRec, setBudgetRec] = useState(budgetReceived);
  const [budgetPend, setBudgetPend] = useState(budgetPending);
  const [budgetTot, setBudgetTot] = useState(budgetTotal);
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    setBudgetTot(budgetTotal);
    if (budgetTot > 0) {
      if (budgetReceived > 0) {
        const receivedPercent = (budgetReceived / budgetTotal) * 100;
        setBudgetRec(receivedPercent.toFixed());
      } else {
        setBudgetRec(0);
      }

      if (budgetPending > 0) {
        const pendingPercent = (budgetPending / budgetTotal) * 100;
        setBudgetPend(pendingPercent.toFixed());
      } else {
        setBudgetPend(0);
      }
    }
  }, []);

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="capitalize text-field-blue font-bold text-2xl">{t("budgetSummary")}</p>
      </div>
      <div>
        <ProgressBar
          amountPaid={budgetRec}
          amountPending={budgetPend}
          budgetTotal={budgetTot}
          showPercent={true}
        />
      </div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="bg-active-green w-10 h-10 rounded-lg" />
          <p className="font-bold text-field-blue">$ {budgetReceived}</p>
        </div>
        <div className="flex items-center">
          <div className="bg-pending-red w-10 h-10 rounded-lg" />
          <p className="font-bold text-field-blue">$ {budgetPending}</p>
        </div>
      </div>
    </div>
  );
}
