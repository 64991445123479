import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestingProjectDeletion: false,
  errorRequestingProjectDeletion: false,
  successRequestingProjectDeletion: false,
  unauthorized: true,
};

const deleteProject = createSlice({
  name: "delete-project",
  initialState,
  reducers: {
    requestProjectDeletion(state, action) {
      state.requestingProjectDeletion = true;
      state.errorRequestingProjectDeletion = false;
      state.successRequestingProjectDeletion = false;
      state.unauthorized = true;
    },
    succesProjectDeletion(state, action) {
      state.requestingProjectDeletion = false;
      state.errorRequestingProjectDeletion = false;
      state.successRequestingProjectDeletion = true;
      state.unauthorized = false;
    },
    errorProjectDeletion(state, action) {
      state.requestingProjectDeletion = false;
      state.errorRequestingProjectDeletion = true;
      state.successRequestingProjectDeletion = false;
      state.unauthorized = true;
    },
  },
});

const { actions, reducer } = deleteProject;

const { requestProjectDeletion, succesProjectDeletion, errorProjectDeletion } = actions;
export  { requestProjectDeletion, succesProjectDeletion, errorProjectDeletion };
export default reducer;
