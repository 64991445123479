import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ProgressBar({
  amountPaid,
  amountPending,
  budgetTotal,
  showPercent
}) {
  const [amountPaidIsZero, setamountPaidIsZero] = useState(false);
  const [amountPendingIsZero, setamountPendingIsZero] = useState(false);
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    if (amountPaid !== 0) {
      setamountPaidIsZero(false);
    } else {
      setamountPaidIsZero(true);
    }

    if (amountPending !== 0) {
      setamountPendingIsZero(false);
    } else {
      setamountPendingIsZero(true);
    }
  }, []);
  return (
    <div className="flex flex-col">
        <p className="capitalize font-bold my-2 text-field-blue">{`${t("budget")}: $ ${budgetTotal}`}</p>
      <div className="flex flex-row rounded-lg bg-slate-400 h-8 w-full">
        <p
          style={{ width: `${amountPaid}%` }}
          className="bg-active-green rounded-l-lg text-xs font-bold text-field-blue"
        >{amountPaidIsZero?"":`${amountPaid}${showPercent?"%":""}`}</p>
        <p
          style={{ width: `${amountPending}%` }}
          className="bg-pending-red text-xs font-bold text-field-blue"
        >{amountPendingIsZero?"":`${amountPending}${showPercent?"%":""}`}</p>
      </div>
    </div>
  );
}
