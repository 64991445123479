import { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar";
import { useTranslation } from "react-i18next";
export default function AdditionalBudgetSummary({additionalBudgetReceived,additionalBudgetPending}) {
    const [additionalBudgetRec, setAdditionalBudgetRec] = useState();
  const [additionalBudgetPend, setAdditionalBudgetPend] = useState();
  const {t,i18n} = useTranslation();

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="text-field-blue font-bold text-2xl capitalize">{t("additionalBudget")}</p>
      </div>
      <div>
        <ProgressBar
          amountPaid={additionalBudgetReceived}
          amountPending={additionalBudgetPending}
          budgetTotal={additionalBudgetReceived+additionalBudgetPending}
          showPercent={false}
        />
      </div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="bg-active-green w-10 h-10 rounded-lg" />
          <p className="font-bold text-field-blue">$ {additionalBudgetReceived}</p>
        </div>
        <div className="flex items-center">
          <div className="bg-pending-red w-10 h-10 rounded-lg" />
          <p className="font-bold text-field-blue">$ {additionalBudgetPending}</p>
        </div>
      </div>
    </div>
  );
}