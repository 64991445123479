import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";
import {
    startFetchAllClientsData as startFetchAllClientsDataSlice,
    sucessFetchAllClientsData as sucessFetchAllClientsDataSlice,
    errorFetchAllClients as errorFetchAllClientsSlice,
    startClearAllClientsData as startClearAllClientsDataSlice,
  } from "../slices/clients.js"

export const startFetchAllClientsData = createAction("START_FETCH_ALL_CLIENTS_DATA"); 
export const sucessFetchAllClientsData = createAction("SUCCESS_FETCH_ALL_CLIENTS_DATA");
export const errorFetchAllClients = createAction("ERROR_FETCH_ALL_CLIENTS");
export const startClearAllClientsData = createAction("START_CLEAR_ALL_CLIENTS_DATA");

export const fetchClients = (token)=>async (dispatch) => {
    const requestOptions = {
        method: "GET",
        headers: {"Authorization": token},
    }
    try {
        dispatch(startFetchAllClientsDataSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"clients",requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"clients",requestOptions);
        }
        const data = await response.json();
        if(data.errors){
            dispatch(errorFetchAllClientsSlice(data.errors));
        }else
        {
            dispatch (sucessFetchAllClientsDataSlice({data}));
            console.log(data);
        }
    } catch (error) {
        dispatch(errorFetchAllClientsSlice({error}));
    }
};