import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isFecthingProjectData: false,
  isLoadingProjectData: false,
  errorFetchingProject: false,
  successFetchingProject: false,
  unauthorized:true,
  projectData: true,
};

const project = createSlice({
  name: "all-proects",
  initialState,
  reducers: {
    startFetchProjectData(state, action) {
      state.isLoadingProjectData = false;
      state.isFecthingProjectData = true;
    },
    sucessFetchProjectData(state, action) {
      state.isLoadingProjectData = false;
      state.isFecthingProjectData = false;
      state.successFetchingProject = true;
      state.projectData = action.payload.data;
      state.errorFetchingProject = null;
      state.unauthorized = false;
    },
    errorFetchProject(state, action) {
      state.isLoadingProjectData = false;
      state.successFetchingProject = false;
      state.isFecthingProjectData = false;
      state.projectData = {};
      state.errorFetchingProject = action.payload.data;
      state.unauthorized = true;
    },
    startClearProjectData(state, action) {
      state.projectData = {};
    },
  },
});

const { actions, reducer } = project;

const {
    startFetchProjectData,
    sucessFetchProjectData,
    errorFetchProject,
    startClearProjectData,
} = actions;

export {
    startFetchProjectData,
    sucessFetchProjectData,
    errorFetchProject,
    startClearProjectData,
};

export default reducer;
