import InputField from "../../components/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptButtonStyle,
  inputFieldLabelStyle,
  inputFieldTextStyleError,
  inputFieldTextStyleNormal,
  cancelButtonStyle,
} from "../../assets/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../redux/actions/newproject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { newProjectClear } from "../../redux/slices/newproject";
import { useTranslation } from "react-i18next";

export default function NewProjectForm(params) {

  const { loggedIn, user_id, username, token, logInErrorData } = useSelector(
    (state) => state.loginInReducer
  );

  const { t, i18n } = useTranslation();

  const clearFormInputs = ()=>{
    setProjectName("");
    setEstimatedBudget(0);
    setCompletionDate("dd/mm/aaaa");
    document.getElementById("newProjectForm").reset()    
  };

  const renderNormalView = () => (
    <div className="flex flex-col items-center">
      <p className="font-bold text-field-blue text-2xl m-10">
        {t("createPojectFormInfo")}
      </p>
      <div className="w-fit">
        <form
          id="newProjectForm"
          className="flex flex-col items-end space-y-2"
          onSubmit={handleSubmit}
        >
          <label className={inputFieldLabelStyle}>
            {t("projectFor")}(*)
            <input type="text" required className={inputFieldTextStyleNormal} />
          </label>
          <label className={inputFieldLabelStyle} >
            {t("startDate")} (*)
            <input
              type="date"
              required
              className={`${
                isStartingDateError
                  ? inputFieldTextStyleError
                  : inputFieldTextStyleNormal
              }`}
              onChange={handleStartingDateChange}
              onKeyDown={handleDateKeyPress}
              value={startingDate}
              min={currentDate}
            />
          </label>
          <label className={inputFieldLabelStyle}>
            {t("projectName")} (*)
            <input
              type="text"
              required
              className={inputFieldTextStyleNormal}
              onChange={handleProjectNameChange}
              value={projectName}
            />
          </label>
          <label className={inputFieldLabelStyle}>
            {t("estimatedBudget")}
            <input
              type="number"
              required
              min="0.01"
              step="0.01"
              className={inputFieldTextStyleNormal}
              onChange={handleEstimatedBudgetChange}
              placeholder="0.00"
              prefix={"JULIAN"}
            />
          </label>
          <label className={inputFieldLabelStyle}>
          {t("dueDate")}(*)
            <input
              type="date"
              required
              className={`${
                isCompletionDateError
                  ? inputFieldTextStyleError
                  : inputFieldTextStyleNormal
              }`}
              onChange={handleCompletionDateChange}
              onKeyDown={handleDateKeyPress}
              value={completionDate}
              min={startingDate}
            />
          </label>
          <label className={inputFieldLabelStyle}>
            {t("contractNo")}(*)
            <input
              type="number"
              required
              className={inputFieldTextStyleNormal}
              onChange={handleContractNoChange}
            />
          </label>
          <label className={inputFieldLabelStyle}>
            {t("orderNo")}(*)
            <input
              type="number"
              required
              className={inputFieldTextStyleNormal}
              onChange={handleOrderNoChange}
            />
          </label>
          <label className={`${inputFieldLabelStyle}`}>
            <div className="flex">
              {t("details2")}
              <textarea
                className={`${inputFieldTextStyleNormal} h-32`}
                onChange={handleProjectDetailsChange}
              />
            </div>
          </label>
          <div className="flex flex-row py-10">
            <button className={cancelButtonStyle} onClick={handleCancelClick}>
              {t("cancel")}
            </button>
            <input className={acceptButtonStyle} type="submit" value={t("create")} />
          </div>
        </form>
      </div>
    </div>
  );

  /**Constants Here */
  const currentDate = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    requestingProjectCreation,
  errorRequestingProjectCreation,
  successCreatingNewProject,
  unauthorized,
  } = useSelector((state) => state.createProjectReducerSlice);

  /**State Variables Here */
  const [startingDate, setStartingDate] = useState();
  const [isStartingDateError, setIsStartingDateError] = useState(false);
  const [completionDate, setCompletionDate] = useState();
  const [isCompletionDateError, setIsCompletionDateError] = useState(false);
  const [isCompletionDateEnabled, setIsCompletionDateEnabled] = useState(true);
  const [estimatedBudget, setEstimatedBudget] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [projectName, setProjectName] = useState("");
  const [contractNo, setContractNo] = useState("");
  const [projectDetails, setProjectDetails] = useState("");

  /**-----------------------------------------------------------------------------------------------
   * Handlers Here
   * ----------------------------------------------------------------------------------------------*/
  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleProjectDetailsChange = (event) => {
    setProjectDetails(event.target.value);
  };

  const handleContractNoChange = (event) => {
    setContractNo(event.target.value);
  };
  const handleCancelClick = (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };
  const handleDateKeyPress = (event) => {
    event.preventDefault();
  };

  const handleEstimatedBudgetChange = (event) => {
    setEstimatedBudget(
      Number(event.target.value)
        .toFixed(2)
        .replace(/\d(?=(\d{10})+\.)/g, "$&,")
    );
  };
  const handleSubmit = (event) => {
    var tokenillo = sessionStorage.getItem("token");
    const projectData = {
      client_id: 2,
      startingDate: startingDate,
      projectName: projectName,
      estimatedBudget: estimatedBudget,
      completionDate: completionDate,
      contractNo: contractNo,
      orderNo: orderNo,
      details: projectDetails,
      user_id: user_id,
      project_status_id: 3,
    };
    dispatch(createProject(tokenillo, projectData));
    event.preventDefault();
  };
  const handleStartingDateChange = (event) => {
    let currentDate = new Date();
    let selectedDate = new Date(event.target.value);
    selectedDate.setDate(selectedDate.getDate() + 1);
    if (selectedDate < currentDate) {
      setIsStartingDateError(true);
      alert(
        "La fecha de comienzo del proyecto no puede ser menor que la fecha actual"
      );
      setIsCompletionDateEnabled(true);
    } else {
      setIsStartingDateError(false);
      setIsCompletionDateEnabled(false);
      setStartingDate(event.target.value);
    }
  };

  const handleOrderNoChange = (event) => {
    setOrderNo(event.target.value);
  };
  const handleCompletionDateChange = (event) => {
    const currentDate = new Date();
    const selectedDate = new Date(event.target.value);
    const _startingDate = new Date(startingDate);
    if (selectedDate > currentDate && selectedDate > _startingDate) {
      setIsCompletionDateError(false);
      setCompletionDate(event.target.value);
    } else {
      setIsCompletionDateError(true);
      alert("Completion date should be after starting date");
    }
  };

  const succesProjectCreationToast = ()=>{
    toast.success("Project Created Succesfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    navigate("/dashboard")
  };

  const errorProjectCreationToast = ()=>{
    toast.error("Error Creating Project", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  useEffect(() => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    currentDate = currentDate.toISOString().split("T")[0];
    setStartingDate(currentDate);
  }, []);

  useEffect(() => {
    if (loggedIn === false) {
      navigate("/");
    }
  }, [loggedIn]);

  /**Cuando el starting Date cambie pues debo verificar que el completionDate no esta puesto a un valor menor */
  useEffect(() => {
    const tempStartingDate = new Date(startingDate);
    const tempCompletionDate = new Date(completionDate);
    if (tempStartingDate > tempCompletionDate) {
      setCompletionDate(startingDate);
    }
  }, [startingDate]);

  useEffect(() => {
    if (successCreatingNewProject === true) {
      succesProjectCreationToast()
      dispatch(newProjectClear())
      clearFormInputs();
    }
  }, [successCreatingNewProject]);

  useEffect(() => {
    if (errorRequestingProjectCreation) {
      errorProjectCreationToast()
      dispatch(newProjectClear())
    }
  }, [errorRequestingProjectCreation]);

  return renderNormalView();
}
