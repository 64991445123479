import { logOut } from "../../redux/slices/login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../LangSelector";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";

import { Avatar } from "@mui/material";
import { green, deepPurple, lightBlue, red } from "@mui/material/colors";
import { useEffect } from "react";

/**IMPORTS FOR THE APP BAR */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

export default function TitleBanner({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOutClick = () => {
    dispatch(logOut());
    navigate("/");
  };
  const { t, i18n } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(true);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect((showDrawer) => {
    setState({ ...state, left: showDrawer });
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleProjectsDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleActiveProjectsClick = () => {
    navigate("/active");
  };

  const handlePendingProjectsClick = () => {
    navigate("/pending");
  };

  const handleCompletedProjectsClick = () => {
    navigate("/completed");
  };

  const handleOpportunityProjectsClick = () => {
    navigate("/opportunity");
  };

  const handleClientsDashboardClick = ()=>{
    navigate("/clients-dashboard")
  }
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItemText sx={{ marginLeft: 2 }}><p className="capitalize font-bold">{t("projectsArea")}</p></ListItemText>
        <ListItemButton
          sx={{ padding: 0 }}
          onClick={handleProjectsDashboardClick}
        >
          <ListItem>
            <Avatar
              sx={{
                bgcolor: lightBlue[900],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              PD
            </Avatar>
            <p className="capitalize">{t("projectsDashboard")}</p>  
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }} onClick={handleActiveProjectsClick}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: green[500],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              AC
            </Avatar>
            <p className="capitalize">{t("active")}</p>            
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }} onClick={handlePendingProjectsClick}>
          <ListItem>
            <Avatar
              sx={{ bgcolor: red[500], marginRight: 1, width: 35, height: 35 }}
            >
              PE
            </Avatar>
            <p className="capitalize">{t("pending")}</p>  
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }} onClick={handleCompletedProjectsClick}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: lightBlue[500],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              CO
            </Avatar>
            <p className="capitalize">{t("completed")}</p> 
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }} onClick={handleOpportunityProjectsClick}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: deepPurple[500],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              OP
            </Avatar>
            <p className="capitalize">{t("opportunity")}</p> 
          </ListItem>
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemText sx={{ marginLeft: 2 }}><p className="capitalize font-bold">{t("clientsArea")}</p></ListItemText>
        <ListItemButton sx={{ padding: 0 }} onClick={handleClientsDashboardClick}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: lightBlue[900],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              CD
            </Avatar>
            <p className="capitalize">{t("clientsDashboard")}</p> 
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: green[500],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              AC
            </Avatar>
            <p className="capitalize">{t("active")}</p> 
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }}>
          <ListItem>
            <Avatar
              sx={{ bgcolor: red[500], marginRight: 1, width: 35, height: 35 }}
            >
              PE
            </Avatar>
            <p className="capitalize">{t("pending")}</p> 
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: lightBlue[500],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              CO
            </Avatar>
            <p className="capitalize">{t("completed")}</p> 
          </ListItem>
        </ListItemButton>
        <ListItemButton sx={{ padding: 0 }}>
          <ListItem>
            <Avatar
              sx={{
                bgcolor: deepPurple[500],
                marginRight: 1,
                width: 35,
                height: 35,
              }}
            >
              OP
            </Avatar>
            <p className="capitalize">{t("opportunity")}</p> 
          </ListItem>
        </ListItemButton>
        <Divider />
        <ListItem>
          <ListItemButton sx={{ padding: 0 }} onClick={handleLogOutClick}>
            <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
            <ListItemText primary={t("logOut")} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <LangSelector />
        </ListItem>
      </List>
    </Box>
  );
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              className="uppercase font-bold"
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Simple Project Manager {title}
            </Typography>
            <Button color="inherit" onClick={handleLogOutClick}>
              {t("logOut")}
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key="left">
            <Drawer
              anchor="left"
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
  return (
    <div className="bg-button-blue uppercase h-20 flex justify-between w-full">
      <p className="text-white font-bold text-3xl self-center w-10/12">
        Simple Project Manager - {title}
      </p>
      <button
        className="m-3 px-3 text-white font-bold rounded-full border-2 w-1/12"
        onClick={handleLogOutClick}
      >
        {t("logOut")}
      </button>
      <div className="w-1/12">
        <LangSelector />
      </div>
    </div>
  );
}
