import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import TitleBanner from "../../components/TitleBanner";
import NewProjectForm from "./NewProjectForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NewProjectPage(params) {
  //Will use them to see if the user is logged in or not.
  const {
    loggedIn,
    user_id,
    username,
    token,
    logInErrorData
  } = useSelector((state)=>state.loginInReducer);

  const {unauthorized} = useSelector((state)=>state.createProjectReducerSlice);

  const navigate = useNavigate();

  useEffect(()=>{
    if (loggedIn === false){

      navigate("/");
    }
   },[loggedIn])

   useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
   },[]);

   const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col">
      <TitleBanner title={t("createProject")} />
      <NavBar activeTab="new"/>
      <NewProjectForm />
      <div className="m-20">
        <Footer />
      </div>
    </div>
  );
}
