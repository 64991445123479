import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";
import {
    requestProjectCreation as requestProjectCreationSlice, 
    successProjectCreation as successProjectCreationSlice, 
    errorProjectCreation as errorProjectCreationSlice,
    newProjectClear as newProjectClearSlice,
    unauthorized as unauthorizedSlice,
} from "../slices/newproject";

export const requestProjectCreation = createAction("REQUEST_PROJECT_CREATION");
export const successProjectCreation = createAction("SUCCESS_PROJECT_CREATION");
export const errorProjectCreation = createAction("ERROR_PROJECT_CREATION");
export const newProjectClear = createAction("NEW_PROJECT_CLEAR");

export const createProject = (token,projectData)=> async (dispatch) => {
    const requestOptions = {
        method: "POST",
        headers: {"Authorization": token,"Content-Type":"application/json"},
        body:JSON.stringify({
            client_id:2,//TODO Tienes que hacer para que se seleccione el cliente de una lista de clientes. Para esto debes tambien ponerle una parte de la app que sea para gestionar tus clientes.
            startingDate:projectData.startingDate,
            projectName:projectData.projectName,
            estimatedBudget:projectData.estimatedBudget,
            completionDate:projectData.completionDate,
            contractNo:projectData.contractNo,
            orderNo:projectData.orderNo,
            details:projectData.details,
            user_id: projectData.user_id,
            project_status_id:2//TODO Tienes que decidir si los proyectos siempre los vas a crear en un mismo estado y si despues se puedne cambiar o la api es la que se encarga de cambiar su estado
            })
    }

    try {
        dispatch(requestProjectCreationSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects",requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects",requestOptions);
        }
        const data = await response.json();
        if (data.status === "ERROR") {
            dispatch(errorProjectCreationSlice(data.message));
        }
        else{
            dispatch (successProjectCreationSlice({data}));
        }
    } catch (error) {
        dispatch(errorProjectCreationSlice({error}));
    }
};