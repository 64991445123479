import { BrowserRouter, Routes, Route } from "react-router-dom";
import FourOFour from "../components/FourOFour";
import ActivePage from "../views/ActivePage";
import ClientsDashboardPage from "../views/ClientsDashboardPage";
import CompletedPage from "../views/CompletedPage";
import DashboardPage from "../views/DashboardPage";
import DetailsPage from "../views/DetailsPage";
import LoginPage from "../views/LoginPage";
import NewProjectPage from "../views/NewProjectPage";
import OpportunityPage from "../views/OpportunityPage";
import PendingPage from "../views/PendingPage";
import RegisterPage from "../views/RegisterPage";

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/register" element={<RegisterPage/>} />
        <Route exact path="/dashboard" element={<DashboardPage/>} />
        <Route exact path="/new-project" element={<NewProjectPage/>} />
        <Route exact path="/active" element={<ActivePage/>} />
        <Route exact path="/pending" element={<PendingPage/>} />
        <Route exact path="/completed" element={<CompletedPage/>} />
        <Route exact path="/opportunity" element={<OpportunityPage/>} />
        <Route exact path="/details/:id" element={<DetailsPage/>} />
        <Route exact path="/clients-dashboard" element={<ClientsDashboardPage/>} />
        <Route path="/*" element={<FourOFour/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
