import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import TitleBanner from "../../components/TitleBanner";
import ProjectSummaryCard from "../../components/ProjectSummaryCard";
import { useState } from "react";
import AddMilestoneModal from "../AddMilestoneModal";
import Milestone from "./Milestone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchProject } from "../../redux/actions/project";
import { fetchMilestones } from "../../redux/actions/milestones";
import Loading from "../../components/Loader";
import { FiPlusCircle } from "react-icons/fi";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function DetailsPage(props) {
  /**---------------------------------------------------------------------------------------------
   * STATES HERE
   ----------------------------------------------------------------------------------------------*/
  const [isAddMilestoneOpen, setIsAddMilestoneOpen] = useState(false);
  const [isSuccesMilestoneCreate, setIsSuccesMilestoneCreate] = useState(false);
  const [isErrorMilestoneCreate, setIsErrorMilestoneCreate] = useState(false);
  const [errorCreatingMilestoneData, setErrorCreatingMilestoneData] =
    useState("");

  const { id } = useParams();
  const {t,i18n} = useTranslation();

  /**-----------------------------------------------------------------------------------------------
   * STATE SELECTORS HERE
   * ----------------------------------------------------------------------------------------------*/
  const {
    successRequestingMilestoneCreate,
    errorRequestingMilestoneCreate,
    errorRequestingMIlestoneCreateData,
  } = useSelector((state) => state.milestoneCreateReducer);

  const { isFetchingMilestones, succesFetchingMilestones, milestonesData } =
    useSelector((state) => state.milestonesReducer);

  const { isFecthingProjectData, successFetchingProject, projectData } =
    useSelector((state) => state.projectReducer);

  //Will use them to see if the user is logged in or not.
  const { loggedIn } = useSelector((state) => state.loginInReducer);

  const navigate = useNavigate();

  /**-----------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ----------------------------------------------------------------------------------------------*/
  useEffect(() => {
    if (loggedIn === false) {
      navigate("/");
    }
  }, [loggedIn]);

  useEffect(() => {
    successRequestingMilestoneCreate === true &&
      setIsSuccesMilestoneCreate(true);
    var tokenillo = sessionStorage.getItem("token");
    dispatch(fetchMilestones(tokenillo, id));
  }, [successRequestingMilestoneCreate]);

  useEffect(() => {
    errorRequestingMilestoneCreate === true && setIsErrorMilestoneCreate(true);
    setErrorCreatingMilestoneData(errorRequestingMIlestoneCreateData);
  }, [errorRequestingMilestoneCreate]);

  const dispatch = useDispatch();

  useEffect(() => {
    var tokenillo = sessionStorage.getItem("token");
    dispatch(fetchProject(tokenillo, id));
    dispatch(fetchMilestones(tokenillo, id));
    setIsErrorMilestoneCreate(false);
    setIsSuccesMilestoneCreate(false);
  }, []);

  /**---------------------------------------------------------------------------------------------
   * HANDLERS HERE
   ----------------------------------------------------------------------------------------------*/
  const handleAddMilestoneClick = () => {
    setIsAddMilestoneOpen(true);
  };

  /**---------------------------------------------------------------------------------------------
   * TOASTS HERE
   ----------------------------------------------------------------------------------------------*/
  const successMilestoneCreateToast = () => {
    setIsSuccesMilestoneCreate(false);
    toast.success("Milestone Created Succesfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    toast.clearWaitingQueue();
  };

  const errorMilestoneCreateToast = () => {
    setIsErrorMilestoneCreate(false);
    toast.error(errorCreatingMilestoneData.details, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    toast.clearWaitingQueue();
  };

  /**---------------------------------------------------------------------------------------------
   * RENDERING HERE
   ----------------------------------------------------------------------------------------------*/
  const renderMilestones = () =>
    milestonesData?.data?.map((value) => {
      return (
        <Milestone
          key={value.id}
          amount={value.amount}
          billNo={value.billNo}
          issueDate={value.dateIssued}
          milestoneStatus={value.milestone_status_id}
          comments={value.details}
          milestoneId={value.id}
          projectId={id}
        />
      );
    });

  /**---------------------------------------------------------------------------------------------
   * RETURN SECTION HERE.
   ----------------------------------------------------------------------------------------------*/
  if (isFecthingProjectData) {
    return <p>FETCHING DATA</p>;
  }

  if (successFetchingProject) {
    return (
      <div className="flex flex-col self-center">
        <TitleBanner title={t("details2")} />
        <NavBar activeTab={t("details2")}  />
        <ProjectSummaryCard
          key={id}
          projectState={"pending"}
          projectName={projectData.data[0][0].projectName}
          projectOwner={"Johana Windsurf"}
          startDate={projectData.data[0][0].startingDate}
          dueDate={projectData.data[0][0].completionDate}
          budgetTotal={projectData.data[0][0].estimatedBudget}
          budgetReceived={projectData.data[0][1].paidBudget}
          budgetPending={projectData.data[0][2].unpaidBudget}
          additionalBudgetReceived={projectData.data[0][3].paidAdditionalBudget}
          additionalBudgetPending={
            projectData.data[0][4].unpaidAdditionalBudget
          }
          milestones={projectData.data[0][5].milestonesCount}
          dueMilestones={projectData.data[0][6].dueMilestonesCount}
          hideControls={true}
        />
        <div className="flex-col">
          <div>
            <IconButton>
              <FiPlusCircle
                onClick={handleAddMilestoneClick}
                className="rounded-full text-4xl text-bold bg-button-blue w-20 h-20 text-white hover:bg-button-over-blue"
              />
            </IconButton>
          </div>
          <p className="font-bold text-field-blue text-xl capitalize">{t("addMilestone")}</p>
        </div>
        {/* -------------------------------------------------------------------------------------- */}
        {/* MILESTONES AREA HERE                                                                   */}
        {/* -------------------------------------------------------------------------------------- */}
        <div className="flex flex-col border-2 m-2 rounded-xl space-y-4 py-4">
          {/**Si esta fetching Milestones pues que muestre el loader. De lo contrario averiguar si ha habido error o se pueden mostrar los datos */}
          {isFetchingMilestones ? (
            <Loading title="Fetching Milestones" body="Please Wait ..." />
          ) : succesFetchingMilestones ? (
            renderMilestones()
          ) : (
            <p>ERROR FETCHING MILESTONES DATA</p>
          )}
        </div>

        <div className="m-20">
          <Footer />
        </div>
        <div style={{ position: "fixed", left: 0, top: 0 }}>
          {isAddMilestoneOpen && (
            <AddMilestoneModal
              setIsOpen={setIsAddMilestoneOpen}
              curentBillNo={"FKOLYU-7894"}
              currentAmount={2500.98}
              currentIssueDate={"2022-08-07"}
              currentMilestonStatus={"unpaid"}
              currentComments={
                "Este es un cliente serio pero esta demorando en pagar."
              }
              projectId={id}
            />
          )}
        </div>
        {/** ------------------------------------------------------------------------------ */}
        {/** TOASTS RENDERING HERE                                                          */}
        {/** ------------------------------------------------------------------------------ */}
        {isSuccesMilestoneCreate === true
          ? successMilestoneCreateToast()
          : undefined}
        {isErrorMilestoneCreate === true
          ? errorMilestoneCreateToast()
          : undefined}
      </div>
    );
  }
}
