import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";

import {
  requestMilestoneDelete as requestMilestoneDeleteSlice,
  successMilestoneDelete as successMilestoneDeleteSlice,
  errorMilestoneDelete as errorMilestoneDeleteSlice,
} from "../slices/milestonedelete";

export const requestMilestoneDelete = createAction("REQUEST_MILESTONE_DELETE");
export const successMilestoneDelete = createAction("SUCCESS_MILESTONE_DELETE");
export const errorMilestoneDelete = createAction("ERROR_MILESTONE_DELETE");

export const milestoneDelete = (token,project_id,milestone_id,milestoneData) => async (dispatch)=>{

    const requestOptions = {
        method: "DELETE",
        headers: {"Authorization": token,"Content-Type":"application/json"},
    }

    try {
        dispatch(requestMilestoneDeleteSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects/"+project_id+"/milestones/"+milestone_id,requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects/"+project_id+"/milestones/"+milestone_id,requestOptions);
        }

        const data = await response.json();

        if (data.status === "SUCCESS") {
            dispatch (successMilestoneDeleteSlice());
        }
        else{
            dispatch(errorMilestoneDeleteSlice(data.message));
        }
    } catch (error) {
        dispatch(errorMilestoneDeleteSlice({error}));
    }

};