import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";

import {
    startLoginIn as startLoginInSlice,
    successLoginIn as successLoginInSlice,
    errorLoginIn as errorLoginInSlice,
    logOut as logOutSlice,
} from "../slices/login.js"

export const startLoginIn = createAction("START_LOGIN_IN");
export const successLoginIn = createAction("SUCCESS_LOGIN_IN");
export const errorLoginIn = createAction("ERROR_LOGIN_IN");
export const logOut = createAction("LOG_OUT");

export const logUserIn = ({email,password})=>async (dispatch)=>{
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body:JSON.stringify({
            email:email,
            password:password
            })
    }

    try {
        dispatch(startLoginInSlice());
        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"auth/login",requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"auth/login",requestOptions);
        }

        const data = await response.json();
        console.log("token es este:");//XXX DALE GUACHA CUANDO TERMINES DE DESARROLLAR
        console.log(data);//XXX DALE GUACHA CUANDO TERMINES DE DESARROLLAR
        if (data.error){
            dispatch(errorLoginInSlice(data.error));
        } else{
            dispatch(successLoginInSlice({data}));
        }
    } catch (error) {
        dispatch(errorLoginInSlice({error}));
    }
};