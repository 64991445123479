import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "./apiconfig";
import {
    startFetchProjectData as startFetchProjectDataSlice,
    sucessFetchProjectData as sucessFetchProjectDataSlice,
    errorFetchProject as errorFetchProjectSlice,
    startClearProjectData as startClearProjectDataSlice,
  } from "../slices/project.js"

export const startFetchProjectData = createAction("START_FETCH_PROJECTS_DATA"); 
export const sucessFetchProjectData = createAction("SUCCESS_FETCH_PROJECT_DATA");
export const errorFetchProject = createAction("ERROR_FETCH_PROJECT");
export const startClearProjectData = createAction("START_CLEAR_PROJECT_DATA");

export const fetchProject = (token,project_id)=>async (dispatch) => {
    const requestOptions = {
        method: "GET",
        headers: {"Authorization": token},
    }
    try {
        dispatch(startFetchProjectDataSlice());

        let response
        if(apiLocation === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"projects/"+project_id,requestOptions);
        } else{
            response = await fetch(apiServerURLRoot+"projects/"+project_id,requestOptions);
        }

        const data = await response.json();
        if(data.errors){
            dispatch(errorFetchProjectSlice(data.errors));
        }else
        {
            dispatch (sucessFetchProjectDataSlice({data}));
        }
    } catch (error) {
        dispatch(errorFetchProjectSlice({error}));
    }
};