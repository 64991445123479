import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import TitleBanner from "../../components/TitleBanner";
import ProjectSummaryCard from "../../components/ProjectSummaryCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CompletedPage(params) {
  //Will use them to see if the user is authorized or not.
  const { loggedIn, user_id, username, token, logInErrorData } = useSelector(
    (state) => state.loginInReducer
  );

  const {
    isFecthingAllProjectsData,
    isLoadingAllProjectsData,
    errorFetchingAllProjects,
    successFetchingAllProjects,
    unauthorized,
    projectsData,
  } = useSelector((state) => state.projectsReducerSlice);



  const completedProjects = projectsData.data.filter(function(project) {
    return project[0].project_status_id === 5;
  })

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (loggedIn === false) {
      navigate("/");
    }
  }, [loggedIn]);

  return (
    <div className="flex flex-col self-center">
      <TitleBanner title={t("completedProjectsPageTitle")} />
      <NavBar activeTab={t("completed")} />
      <div className="flex flex-col space-y-3">
      {completedProjects?.map((value) => {
          return (
            <ProjectSummaryCard
              key={value[0].id}
              projectState={"completed"}
              projectName={value[0].projectName}
              project_id={value[0].id}
              projectOwner={"Kiko Villamontes"}
              startDate={value[0].startingDate}
              dueDate={value[0].completionDate}
              budgetTotal={value[0].estimatedBudget}
              budgetReceived={value[1].paidBudget}
              budgetPending={value[2].unpaidBudget}
              additionalBudgetReceived={value[3].paidAdditionalBudget}
              additionalBudgetPending={value[4].unpaidAdditionalBudget}
              milestones={value[5].milestonesCount}
              dueMilestones={value[6].dueMilestonesCount}
              hideControls={false}
            />
          );
        })}
      </div>
      <div className="m-20">
        <Footer />
      </div>
    </div>
  );
}
