import { configureStore } from "@reduxjs/toolkit";
import projectsReducerSlice from "../redux/slices/projects"
import loginInReducer from "../redux/slices/login"
import createProjectReducerSlice from "../redux/slices/newproject"
import deleteProjectReducer from "../redux/slices/deleteproject"
import projectReducer from "../redux/slices/project"
import milestonesReducer from "../redux/slices/milestones"
import milestoneUpdateReducer from "../redux/slices/milestoneupdate"
import milestoneCreateReducer from "../redux/slices/milestonecreate"
import milestoneDeleteReducer from "../redux/slices/milestonedelete"
import registerReducer from "../redux/slices/register"
import clientsReducer from "../redux/slices/clients"

export const store = configureStore({
    reducer: {
        projectsReducerSlice,
        loginInReducer,
        createProjectReducerSlice,
        deleteProjectReducer,
        projectReducer,
        milestonesReducer,
        milestoneUpdateReducer,
        milestoneCreateReducer,
        milestoneDeleteReducer,
        registerReducer,
        clientsReducer
    },
  });