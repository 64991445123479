import './App.css';
import LoginPage from './views/LoginPage';
import RoutesComponent from './routes';
import { ToastContainer, toast } from "react-toastify";


function App() {
  return (
    <div className="App">
      <ToastContainer limit={1} autoClose={3000}/>
      <RoutesComponent/>
    </div>
    
  );
}

export default App;
