import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import ProjectSummaryCard from "../../components/ProjectSummaryCard";
import TitleBanner from "../../components/TitleBanner";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchProjects } from "../../redux/actions/projects";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";


export default function DashboardPage(params) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    isFecthingAllProjectsData,
    isLoadingAllProjectsData,
    errorFetchingAllProjects,
    successFetchingAllProjects,
    unauthorized,
    projectsData,
  } = useSelector((state) => state.projectsReducerSlice);

  const projectStatuses = ["none","active","pending","opportunity","completed"]

  const { loggedIn, user_id, username, token, logInErrorData } = useSelector(
    (state) => state.loginInReducer
  );

  useEffect(() => {
    var tokenillo = sessionStorage.getItem("token");
    dispatch(fetchProjects(tokenillo));
  }, []);

  const handleCreateNewProjectClick = () => {
    navigate("/new-project");
  };

  if (unauthorized) {
    return "UNAUTHORIZED";
  } else {
  }
  return (
    <div className="flex flex-col self-center">
      <TitleBanner title={t("DashboardPageTitle")} />
      <NavBar activeTab="dashboard" />
      <div className="flex-col">
          <div>
            <IconButton>
              <FiPlusCircle
                onClick={handleCreateNewProjectClick}
                className="rounded-full text-4xl text-bold bg-button-blue w-20 h-20 text-white hover:bg-button-over-blue"
              />
            </IconButton>
          </div>
          <p className="font-bold text-field-blue text-xl">
            {t("createProject")}
          </p>
        </div>

      <div className="flex flex-col space-y-3">
        {projectsData?.data?.map((value) => {
          return (
            <ProjectSummaryCard
              key={value[0].id}
              projectState={projectStatuses[value[0].project_status_id-1]}//Aqui es -1 porque el indice del arreglo es base 0 pero los estados enmpioezan poir el 1
              projectName={value[0].projectName}
              project_id={value[0].id}
              projectOwner={"Kiko Villamontes"}
              startDate={value[0].startingDate}
              dueDate={value[0].completionDate}
              budgetTotal={value[0].estimatedBudget}
              budgetReceived={value[1].paidBudget}
              budgetPending={value[2].unpaidBudget}
              additionalBudgetReceived={value[3].paidAdditionalBudget}
              additionalBudgetPending={value[4].unpaidAdditionalBudget}
              milestones={value[5].milestonesCount}
              dueMilestones={value[6].dueMilestonesCount}
              hideControls={false}
            />
          );
        })}
        
      </div>
      <div className="m-20">
        <Footer />
      </div>
    </div>
  );
}
