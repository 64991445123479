import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import { Avatar } from "@mui/material";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import { green, deepPurple, lightBlue, red } from "@mui/material/colors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const summaryCardSecondaryDataTextColor = "#8B8181"

export default function ClientSummaryCard({
  name,
  email,
  phone,
  address,
  activeCount,
  pendingCount,
  opportunityCount,
  completedCount,
  clientDetails,
}) {
    const [expanded, setExpanded] = useState(false);
    const [clientSummaryCardHeight,setClientSummaryCardHeight] = useState(300);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };


  return (
    <Box
    minHeight={345}
      sx={{
        width: 550,
        height: {clientSummaryCardHeight},
        boxShadow: "0px 0px 15px black ",
        borderRadius: "20px"
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box sx={{ paddingLeft: "15px", paddingTop: "15px" }}>
          <Avatar sx={{ width: 63, height: 63 }}>H</Avatar>
        </Box>

        <Stack>
          <Typography variant="h4" sx={{ paddingTop: "25px" }}>
            {name}
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "15px" }}>
            <MailOutlineIcon sx={{ width: 32, height: 32,color:`${summaryCardSecondaryDataTextColor}` }} />
            <Typography variant="h6" sx={{ paddingLeft: "11px",color:`${summaryCardSecondaryDataTextColor}`}}>
              {email}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ paddingTop: "15px" }}>
            <LocalPhoneIcon sx={{ width: 32, height: 32,color:`${summaryCardSecondaryDataTextColor}` }} />
            <Typography variant="h6" sx={{ paddingLeft: "11px",color:`${summaryCardSecondaryDataTextColor}` }}>
              {phone}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ paddingTop: "15px" }}>
            <PlaceIcon sx={{ width: 32, height: 32,color:`${summaryCardSecondaryDataTextColor}` }} />
            <Typography variant="h6" sx={{ paddingLeft: "11px",color:`${summaryCardSecondaryDataTextColor}`,textAlign:"left" }}>
              {address}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ paddingTop: "15px" }}>
            <div className="flex flex-row items-center mr-5">
              <Avatar
                sx={{
                  bgcolor: green[500],
                  marginRight: 1,
                  width: 35,
                  height: 35,
                }}
              >
                AC
              </Avatar>
              <p className="capitalize">{activeCount}</p>
            </div>
            <div className="flex flex-row items-center mr-5">
              <Avatar
                sx={{
                  bgcolor: red[500],
                  marginRight: 1,
                  width: 35,
                  height: 35,
                }}
              >
                PE
              </Avatar>
              <p className="capitalize">{pendingCount}</p>
            </div>
            <div className="flex flex-row items-center mr-5">
              <Avatar
                sx={{
                  bgcolor: deepPurple[500],
                  marginRight: 1,
                  width: 35,
                  height: 35,
                }}
              >
                OP
              </Avatar>
              <p className="capitalize">{opportunityCount}</p>
            </div>
            <div className="flex flex-row items-center">
              <Avatar
                sx={{
                  bgcolor: lightBlue[500],
                  marginRight: 1,
                  width: 35,
                  height: 35,
                }}
              >
                CO
              </Avatar>
              <p className="capitalize">{completedCount}</p>
            </div>
          </Stack>
        </Stack>
        <Stack direction="row">
            <VisibilityIcon sx={{width:32,height:32,marginTop:"12px",marginRight:"5px"}}/>
            <EditIcon sx={{width:32,height:32,marginTop:"12px",marginRight:"5px"}}/>
        </Stack>
      </Stack>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{marginTop:"10px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="flex items-center">
            More About Client
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{textAlign:"justify"}}>
            {clientDetails}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </Box>
  );
}
